import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  mimetype: Scalars['String'];
  createdAt: Scalars['DateTime'];
  uri: Scalars['String'];
};


export type Role = {
  __typename?: 'Role';
  id: RoleIdEnum;
  name: Scalars['String'];
};

export enum RoleIdEnum {
  Admin = 'admin',
  Ae = 'ae',
  Am = 'am',
  AmManager = 'am_manager',
  AeManager = 'ae_manager',
  Finance = 'finance',
  Op = 'op',
  Gm = 'gm'
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
  roles: Array<Role>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  signedDate?: Maybe<Scalars['DateTime']>;
  status: PurchaseOrderStatusEnum;
  paidDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  initiateDate?: Maybe<Scalars['DateTime']>;
  gmReviewDate?: Maybe<Scalars['DateTime']>;
  financeReviewDate?: Maybe<Scalars['DateTime']>;
  managerReviewDate?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  orderItem: OrderItem;
  supplier: Supplier;
  files: Array<File>;
  invoiceFiles: Array<File>;
  gmReviewer?: Maybe<User>;
  initiator?: Maybe<User>;
  financeReviewer?: Maybe<User>;
  managerReviewer?: Maybe<User>;
};

export enum PurchaseOrderStatusEnum {
  Draft = 'draft',
  ManagerReview = 'managerReview',
  FinanceReview = 'financeReview',
  Unpaid = 'unpaid',
  Paid = 'paid',
  GmReview = 'gmReview'
}

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  invoiceTitle?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  products: Array<Product>;
  bankCode?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  contacts: Array<Contact>;
  purchaseOrders: Array<PurchaseOrder>;
};

export type Interval = {
  __typename?: 'Interval';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  lowerBound?: Maybe<Scalars['Float']>;
  upperBound?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

export type Rebate = {
  __typename?: 'Rebate';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  products: Array<Product>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  intervals: Array<Interval>;
  type?: Maybe<OrderItemType>;
};

/** intermediary=掛告, bid=標案, fixedEdition=媒體定版, integratedProject=整合型專案, brand=品牌型 */
export enum OrderItemType {
  Service = 'service',
  Kpi = 'kpi',
  Intermediary = 'intermediary',
  Bid = 'bid',
  FixedEdition = 'fixedEdition',
  IntegratedProject = 'integratedProject',
  Brand = 'brand'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  /** Product vendor. E.g. google, facebook */
  productOrigin?: Maybe<ProductOrigin>;
  suppliers?: Maybe<Array<Supplier>>;
  rebates?: Maybe<Array<Rebate>>;
};

export enum ProductOrigin {
  Facebook = 'Facebook',
  Google = 'Google',
  Other = 'Other',
  Line = 'LINE',
  Lineoa = 'LINEOA',
  Yahoo = 'Yahoo',
  LiveStreaming = 'LiveStreaming',
  Kooshopping = 'Kooshopping',
  Seo = 'SEO',
  Baidu = 'Baidu',
  Tender = 'Tender',
  Tiktok = 'Tiktok',
  Dcard = 'Dcard',
  Linkedin = 'Linkedin',
  Twitter = 'Twitter',
  Koosocial = 'Koosocial',
  Criteo = 'Criteo',
  Naver = 'NAVER'
}

export type ReceivedPayment = {
  __typename?: 'ReceivedPayment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  note?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status: ReceivedPaymentStatusEnum;
  sourceBankAccountNumber?: Maybe<Scalars['String']>;
  sourceBankCode?: Maybe<Scalars['String']>;
  destinationBankAccountNumber?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  invoices: Array<Invoice>;
};

export enum ReceivedPaymentStatusEnum {
  Started = 'started',
  Linked = 'linked',
  Cancelled = 'cancelled'
}

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  /** 開立發票日期 */
  date?: Maybe<Scalars['DateTime']>;
  /** 回填日期 */
  postDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  items: Array<InvoiceItem>;
  status?: Maybe<InvoiceStatus>;
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Note that this is a percentage! */
  discountPercentage?: Maybe<Scalars['Float']>;
  currency: CurrencyEnum;
  description?: Maybe<Scalars['String']>;
  /** This field is only for USD invoices to enter NTD total amount */
  totalAmount?: Maybe<Scalars['Float']>;
  paidAmount?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  creditDate?: Maybe<Scalars['DateTime']>;
  order: Order;
};

export enum InvoiceStatus {
  Started = 'started',
  Posted = 'posted',
  CancelRequested = 'cancelRequested',
  Canceled = 'canceled',
  Paid = 'paid',
  Changed = 'changed',
  Pending = 'pending',
  Errored = 'errored'
}

export enum CurrencyEnum {
  Twd = 'TWD',
  Usd = 'USD'
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  amount: Scalars['Float'];
  changedAmount: Scalars['Float'];
  orderItem: OrderItem;
};

export type DepositChangeRecord = {
  __typename?: 'DepositChangeRecord';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  verified?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  deposit?: Maybe<Deposit>;
};

export type Deposit = {
  __typename?: 'Deposit';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  status: DepositStatus;
  /** CID */
  accountId?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  changes: Array<DepositChangeRecord>;
  orderItem: OrderItem;
  accountCosts: Array<AccountCost>;
};

export enum DepositStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Canceled = 'canceled',
  Finished = 'finished',
  Changed = 'changed'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  rebate?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  totalAmount?: Maybe<Scalars['Float']>;
  status: OrderItemStatus;
  type?: Maybe<OrderItemType>;
  hasRewarded: Scalars['Boolean'];
  productTag?: Maybe<Scalars['String']>;
  fieldTag?: Maybe<Scalars['String']>;
  belonging?: Maybe<OrderItemBelonging>;
  order: Order;
  deposits: Array<Deposit>;
  product?: Maybe<Product>;
  assignee?: Maybe<User>;
  invoiceItems?: Maybe<Array<InvoiceItem>>;
  purchaseOrders: Array<PurchaseOrder>;
  supporter?: Maybe<User>;
};

export enum OrderItemStatus {
  Started = 'started',
  Finished = 'finished',
  BonusAssigned = 'bonusAssigned'
}

export enum OrderItemBelonging {
  Koodata = 'koodata',
  Adup = 'adup',
  InternationalDivision = 'internationalDivision'
}

export type Industry = {
  __typename?: 'Industry';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type Advertiser = {
  __typename?: 'Advertiser';
  id: Scalars['ID'];
  name: Scalars['String'];
  industryTag?: Maybe<Scalars['String']>;
  productTag?: Maybe<Scalars['String']>;
  fieldTag?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  industry?: Maybe<Industry>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  number: Scalars['String'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoiceMethod?: Maybe<OrderInvoiceMethods>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  status: OrderStatus;
  currency: CurrencyEnum;
  locale: LocaleEnum;
  paymentDays?: Maybe<Scalars['Int']>;
  expectedInvoiceDate: Scalars['DateTime'];
  renewal: Scalars['Boolean'];
  changeReason?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  dealId?: Maybe<Scalars['Int']>;
  customer: Customer;
  contact?: Maybe<Contact>;
  invoiceContact?: Maybe<Contact>;
  items: Array<OrderItem>;
  files: Array<File>;
  invoices: Array<Invoice>;
  assignedSalesUser?: Maybe<User>;
  advertiser?: Maybe<Advertiser>;
};

export enum OrderInvoiceMethods {
  ThisMonth = 'thisMonth',
  Upfront = 'upfront',
  Monthly = 'monthly',
  AfterCompletion = 'afterCompletion'
}

export enum OrderStatus {
  Draft = 'draft',
  Ready = 'ready',
  OrderSigned = 'orderSigned',
  InvoiceRequested = 'invoiceRequested',
  Finished = 'finished',
  Changed = 'changed'
}

export enum LocaleEnum {
  EnUs = 'en_US',
  ZhTw = 'zh_TW'
}

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  supplier: Supplier;
};

export type CustomerAnswerEntity = {
  __typename?: 'CustomerAnswerEntity';
  id: Scalars['ID'];
  operationType?: Maybe<Scalars['String']>;
  years?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  lastYearTransaction?: Maybe<Scalars['String']>;
  paidCredit?: Maybe<Scalars['String']>;
  negativeRecord?: Maybe<Scalars['String']>;
  bouncedRecord?: Maybe<Scalars['String']>;
};

export type UpgradeEntity = {
  __typename?: 'UpgradeEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: UpgradeStatus;
  description?: Maybe<Scalars['String']>;
  lastThreeMonthAmount?: Maybe<Scalars['Float']>;
  targetCreditClass?: Maybe<CreditClassEnum>;
  receivableAmount?: Maybe<Scalars['Float']>;
  unrecordedAmount?: Maybe<Scalars['Float']>;
  overdue?: Maybe<Scalars['Boolean']>;
  financeComment?: Maybe<Scalars['String']>;
  managerComment?: Maybe<Scalars['String']>;
  gmComment?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  ae?: Maybe<User>;
  finance?: Maybe<User>;
  manager?: Maybe<User>;
  gm?: Maybe<User>;
};

export enum UpgradeStatus {
  Draft = 'draft',
  FinanceReview = 'financeReview',
  ManagerReview = 'managerReview',
  GmReview = 'gmReview',
  Success = 'success',
  Rejected = 'rejected'
}

export enum CreditClassEnum {
  S = 'S',
  A = 'A',
  B = 'B',
  C = 'C'
}

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  invoiceMethod?: Maybe<OrderInvoiceMethods>;
  bankCode?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountName?: Maybe<Scalars['String']>;
  passbookImageUrl?: Maybe<Scalars['String']>;
  isAgent: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Note that this is a percentage! */
  defaultDiscountPercentage?: Maybe<Scalars['Float']>;
  /** agent=代理商, direct=直客 */
  type?: Maybe<CustomerTypeEnum>;
  defaultPaymentDays?: Maybe<Scalars['Int']>;
  disabled: Scalars['Boolean'];
  virtualAccountNumber?: Maybe<Scalars['String']>;
  contractStartDate?: Maybe<Scalars['DateTime']>;
  contractEndDate?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['Int']>;
  creditClass?: Maybe<CreditClassEnum>;
  registerDate?: Maybe<Scalars['DateTime']>;
  capital?: Maybe<Scalars['Float']>;
  answer?: Maybe<CustomerAnswerEntity>;
  answerEditable?: Maybe<Scalars['Boolean']>;
  industryTag?: Maybe<Scalars['String']>;
  productTag?: Maybe<Scalars['String']>;
  fieldTag?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  invoiceExpired?: Maybe<Scalars['Boolean']>;
  orders: Array<Order>;
  contacts: Array<Contact>;
  defaultInvoiceContact: Contact;
  defaultContact: Contact;
  assignee?: Maybe<User>;
  industry?: Maybe<Industry>;
  advertisers: Array<Advertiser>;
  invoices: Array<Invoice>;
  receivedPayments: Array<ReceivedPayment>;
  contractFiles: Array<File>;
  rebates: Array<Rebate>;
};

export enum CustomerTypeEnum {
  Agent = 'agent',
  Direct = 'direct'
}

export type PaginatedCustomerResponse = {
  __typename?: 'PaginatedCustomerResponse';
  list: Array<Customer>;
  total: Scalars['Int'];
};

export type PaginatedInvoiceResponse = {
  __typename?: 'PaginatedInvoiceResponse';
  list: Array<Invoice>;
  total: Scalars['Int'];
};

export type PaginatedOrderResponse = {
  __typename?: 'PaginatedOrderResponse';
  list: Array<Order>;
  total: Scalars['Int'];
};

export type AccountCost = {
  __typename?: 'AccountCost';
  id: Scalars['ID'];
  accountId: Scalars['String'];
  cost: Scalars['Float'];
  productOrigin: ProductOrigin;
  date: Scalars['DateTime'];
};

export type RealAccountCost = {
  __typename?: 'RealAccountCost';
  id: Scalars['ID'];
  accountId: Scalars['String'];
  cost: Scalars['Float'];
  month: Scalars['Float'];
  year: Scalars['Float'];
  productOrigin: ProductOrigin;
};

export type OrderItemReportRow = {
  __typename?: 'OrderItemReportRow';
  /** order item id */
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  name: Scalars['String'];
  customerName: Scalars['String'];
  productOrigin: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  accountIds: Array<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  totalAmount: Scalars['Float'];
  budget: Scalars['Float'];
  serviceFee: Scalars['Float'];
  summarizedTotal: Scalars['Float'];
  serviceFeePercentage: Scalars['Float'];
  markup: Scalars['Float'];
  markupPercentage: Scalars['Float'];
  estimatedProfit: Scalars['Float'];
  estimatedProfitPercentage: Scalars['Float'];
  estimatedCostPercentage: Scalars['Float'];
  overSpendAmount: Scalars['Float'];
  overSpendRatio: Scalars['Float'];
  overSpendCostRatio: Scalars['Float'];
  currentMonthCosts: Scalars['Float'];
  previousCosts: Scalars['Float'];
  accumulatedCosts: Scalars['Float'];
  previousIncome: Scalars['Float'];
  currentMonthIncome: Scalars['Float'];
  accumulatedIncome: Scalars['Float'];
  previouslyOutstandingAmount: Scalars['Float'];
  currentMonthOutstandingAmount: Scalars['Float'];
  accumulatedOutstandingAmount: Scalars['Float'];
};

export type GoogleFbCampaign = {
  __typename?: 'GoogleFbCampaign';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PaginatedOrderItemResponse = {
  __typename?: 'PaginatedOrderItemResponse';
  list: Array<OrderItem>;
  total: Scalars['Int'];
};

export type PaginatedDepositResponse = {
  __typename?: 'PaginatedDepositResponse';
  list: Array<Deposit>;
  total: Scalars['Int'];
};

export type PaginatedPurchaseOrderResponse = {
  __typename?: 'PaginatedPurchaseOrderResponse';
  list: Array<PurchaseOrder>;
  total: Scalars['Int'];
};

export type PaginatedReceivedPaymentResponse = {
  __typename?: 'PaginatedReceivedPaymentResponse';
  list: Array<ReceivedPayment>;
  total: Scalars['Int'];
};

export type Log = {
  __typename?: 'Log';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  entityId: Scalars['ID'];
  queryEntity: Scalars['String'];
  queryAction: Scalars['String'];
  queryMessage: Scalars['String'];
  user?: Maybe<User>;
};

export type PaginatedLogResponse = {
  __typename?: 'PaginatedLogResponse';
  list: Array<Log>;
  total: Scalars['Int'];
};

export type PaginatedUpgradeEntityResponse = {
  __typename?: 'PaginatedUpgradeEntityResponse';
  list: Array<UpgradeEntity>;
  total: Scalars['Int'];
};

export type GoogleAdsTemplate = {
  __typename?: 'GoogleAdsTemplate';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  accountCampaigns: Scalars['JSON'];
  adTypes?: Maybe<Array<Scalars['String']>>;
  reportNames?: Maybe<Array<Scalars['String']>>;
  removeUnexposed: Scalars['Boolean'];
  metricsFields?: Maybe<Array<Scalars['String']>>;
  assignee?: Maybe<User>;
};


export type GoogleAdsRecord = {
  __typename?: 'GoogleAdsRecord';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<RecordStatus>;
  template: GoogleAdsTemplate;
  assignee?: Maybe<User>;
};

export enum RecordStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error'
}

export type GoogleAdsAccount = {
  __typename?: 'GoogleAdsAccount';
  id: Scalars['String'];
  level: Scalars['String'];
  isManager: Scalars['Boolean'];
  name: Scalars['String'];
};

export type GoogleAdsCampaign = {
  __typename?: 'GoogleAdsCampaign';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type MarkupSetting = {
  __typename?: 'MarkupSetting';
  id: Scalars['ID'];
  accountId: Scalars['String'];
  adType?: Maybe<Scalars['String']>;
  defaultMarkup: Scalars['Int'];
  markupMap: Scalars['JSON'];
};

export type MetaAdsAccount = {
  __typename?: 'MetaAdsAccount';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type MetaAdsCampaign = {
  __typename?: 'MetaAdsCampaign';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type MetaAdsTemplate = {
  __typename?: 'MetaAdsTemplate';
  id: Scalars['Float'];
  name: Scalars['String'];
  accountCampaigns: Scalars['JSON'];
  reportNames?: Maybe<Array<Scalars['String']>>;
  metricsFields?: Maybe<Array<Scalars['String']>>;
  removeUnexposed: Scalars['Boolean'];
  assignee?: Maybe<User>;
};

export type MetaAdsRecord = {
  __typename?: 'MetaAdsRecord';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<RecordStatus>;
  template: MetaAdsTemplate;
  assignee?: Maybe<User>;
};

export type Query = {
  __typename?: 'Query';
  customer?: Maybe<Customer>;
  customers: PaginatedCustomerResponse;
  industries: Array<Industry>;
  invoice?: Maybe<Invoice>;
  invoices: PaginatedInvoiceResponse;
  supplier: Supplier;
  suppliers: Array<Supplier>;
  product: Product;
  products: Array<Product>;
  order?: Maybe<Order>;
  orders: PaginatedOrderResponse;
  users: Array<User>;
  me: User;
  roles: Array<Role>;
  orderItems: PaginatedOrderItemResponse;
  orderItem?: Maybe<OrderItem>;
  googleFbCampaigns: Array<GoogleFbCampaign>;
  orderItemReport: Array<OrderItemReportRow>;
  realAccountCosts: Array<RealAccountCost>;
  accountCosts: Array<AccountCost>;
  deposits: PaginatedDepositResponse;
  deposit: Deposit;
  purchaseOrder: PurchaseOrder;
  purchaseOrders: PaginatedPurchaseOrderResponse;
  receivedPayments: PaginatedReceivedPaymentResponse;
  receivedPayment: ReceivedPayment;
  logs: PaginatedLogResponse;
  upgrades: PaginatedUpgradeEntityResponse;
  upgradeList: Array<UpgradeEntity>;
  upgrade: UpgradeEntity;
  googleAdsTemplates: Array<GoogleAdsTemplate>;
  googleAdsRecords: Array<GoogleAdsRecord>;
  adsAccounts: Array<GoogleAdsAccount>;
  campaigns: Array<GoogleAdsCampaign>;
  markupSettings: Array<MarkupSetting>;
  getMarkupSettingByAccountAndType?: Maybe<MarkupSetting>;
  metaAdsAccounts: Array<MetaAdsAccount>;
  metaAdsCampaigns: Array<MetaAdsCampaign>;
  metaAdsTemplates: Array<MetaAdsTemplate>;
  metaAdsRecords: Array<MetaAdsRecord>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryInvoicesArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryOrderItemsArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryOrderItemArgs = {
  id: Scalars['ID'];
};


export type QueryGoogleFbCampaignsArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryOrderItemReportArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryRealAccountCostsArgs = {
  productOrigin: ProductOrigin;
  year: Scalars['Int'];
  month: Scalars['Int'];
};


export type QueryAccountCostsArgs = {
  accountId: Scalars['String'];
};


export type QueryDepositsArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryDepositArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrdersArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryReceivedPaymentsArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryReceivedPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryLogsArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryUpgradesArgs = {
  pagination?: Maybe<PaginationInputType>;
  ordering?: Maybe<OrderingInputType>;
  filtering?: Maybe<Array<FilteringInputType>>;
};


export type QueryUpgradeArgs = {
  id: Scalars['ID'];
};


export type QueryCampaignsArgs = {
  cid: Scalars['ID'];
};


export type QueryGetMarkupSettingByAccountAndTypeArgs = {
  adType: Scalars['String'];
  accountId: Scalars['String'];
};


export type QueryMetaAdsCampaignsArgs = {
  accountId: Scalars['String'];
};

export type PaginationInputType = {
  pageIndex: Scalars['Float'];
  pageSize: Scalars['Float'];
};

export type OrderingInputType = {
  id?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['Boolean']>;
};

export type FilteringInputType = {
  key: Scalars['String'];
  value: Scalars['String'];
  comparator: Comparator;
};

export enum Comparator {
  Equals = 'EQUALS',
  Like = 'LIKE',
  Starts = 'STARTS',
  Ends = 'ENDS',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
  Isnull = 'ISNULL',
  Notnull = 'NOTNULL'
}

export type Mutation = {
  __typename?: 'Mutation';
  createCustomer: Customer;
  updateCustomer: Customer;
  removeCustomer: Scalars['ID'];
  createIndustry: Industry;
  deleteIndustry: Scalars['Int'];
  createAdvertiser: Advertiser;
  updateAdvertiser: Advertiser;
  removeAdvertiser: Scalars['ID'];
  createInvoice: Invoice;
  createInvoices: Array<Invoice>;
  updateInvoice: Invoice;
  removeInvoice: Scalars['ID'];
  removeInvoiceItem: Scalars['ID'];
  createSupplier: Supplier;
  updateSupplier: Supplier;
  removeSupplier: Scalars['Int'];
  createProduct: Product;
  updateProduct: Product;
  removeProduct: Scalars['ID'];
  createOrder: Order;
  updateOrderStatus: Order;
  removeOrder: Scalars['ID'];
  updateOrder: Order;
  createUser: User;
  removeUser: Scalars['ID'];
  updateUser: User;
  createContact: Contact;
  removeContact: Scalars['ID'];
  updateContact: Contact;
  createOrderItem: OrderItem;
  updateOrderItem: OrderItem;
  /** @deprecated Use removeOrderItem instead */
  deleteOrderItem: Scalars['ID'];
  removeOrderItem: Scalars['ID'];
  removeRealAccountCosts: Scalars['Boolean'];
  removeFile: Scalars['ID'];
  createDeposit: Deposit;
  updateDeposit: Deposit;
  removeDeposit: Scalars['ID'];
  removeAllDeposit: Scalars['Boolean'];
  addChangeRecord: Deposit;
  reviewDeposit: Deposit;
  createDepositChangeRecord: DepositChangeRecord;
  removeDepositChangeRecord: Scalars['ID'];
  updateDepositChangeRecord: DepositChangeRecord;
  createPurchaseOrder: PurchaseOrder;
  updatePurchaseOrder: PurchaseOrder;
  deletePurchaseOrder: Scalars['ID'];
  noop: Scalars['Boolean'];
  createReceivedPayment: ReceivedPayment;
  linkReceivedPayment: ReceivedPayment;
  updateReceivedPayment: ReceivedPayment;
  removeReceivedPayment: Scalars['ID'];
  pushMessage: Scalars['Boolean'];
  createLog: Log;
  createUpgrade: UpgradeEntity;
  removeUpgrade: Scalars['ID'];
  updateUpgrade: UpgradeEntity;
  createRebate: Rebate;
  updateRebate: Rebate;
  removeRebate: Scalars['Int'];
  createGoogleAdsTemplate: GoogleAdsTemplate;
  removeGoogleAdsTemplate: Scalars['ID'];
  updateGoogleAdsTemplate: GoogleAdsTemplate;
  createGoogleAdsRecord: GoogleAdsRecord;
  removeGoogleAdsRecord: Scalars['ID'];
  updateGoogleAdsRecord: GoogleAdsTemplate;
  upsertMarkupSetting: MarkupSetting;
  removeMarkupSetting: Scalars['Boolean'];
  createMetaAdsTemplate: MetaAdsTemplate;
  updateMetaAdsTemplate: MetaAdsTemplate;
  removeMetaAdsTemplate: Scalars['ID'];
  createMetaAdsRecord: MetaAdsRecord;
  removeMetaAdsRecord: Scalars['ID'];
  updateMetaAdsRecord: MetaAdsTemplate;
  createOrderAndItemsInJira: Scalars['String'];
  syncOrderItemTasks: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  customer: CustomerInputType;
};


export type MutationUpdateCustomerArgs = {
  customer: CustomerInputType;
  id: Scalars['ID'];
};


export type MutationRemoveCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateIndustryArgs = {
  name: Scalars['String'];
};


export type MutationDeleteIndustryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAdvertiserArgs = {
  advertiser: AdvertiserInputType;
};


export type MutationUpdateAdvertiserArgs = {
  advertiser: AdvertiserInputType;
  id: Scalars['ID'];
};


export type MutationRemoveAdvertiserArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInvoiceArgs = {
  invoice: CreateInvoiceDto;
};


export type MutationCreateInvoicesArgs = {
  invoices: Array<CreateInvoiceDto>;
};


export type MutationUpdateInvoiceArgs = {
  invoice: UpdateInvoiceDto;
  id: Scalars['ID'];
};


export type MutationRemoveInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveInvoiceItemArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSupplierArgs = {
  supplier: SupplierInputType;
};


export type MutationUpdateSupplierArgs = {
  supplier: SupplierInputType;
  id: Scalars['ID'];
};


export type MutationRemoveSupplierArgs = {
  id: Scalars['ID'];
};


export type MutationCreateProductArgs = {
  product: ProductInputType;
};


export type MutationUpdateProductArgs = {
  product: ProductInputType;
  id: Scalars['ID'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrderArgs = {
  order: CreateOrderDto;
};


export type MutationUpdateOrderStatusArgs = {
  statusDto: UpdateOrderStatusDto;
  id: Scalars['ID'];
};


export type MutationRemoveOrderArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateOrderArgs = {
  order: UpdateOrderDto;
  id: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  user: CreateUserDto;
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  user: UpdateUserDto;
  id: Scalars['ID'];
};


export type MutationCreateContactArgs = {
  contact: ContactInputType;
};


export type MutationRemoveContactArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateContactArgs = {
  contact: ContactInputType;
  id: Scalars['ID'];
};


export type MutationCreateOrderItemArgs = {
  orderItem: CreateOrderItemDto;
};


export type MutationUpdateOrderItemArgs = {
  orderItem: UpdateOrderItemDto;
  id: Scalars['ID'];
};


export type MutationDeleteOrderItemArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveOrderItemArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveRealAccountCostsArgs = {
  productOrigin: ProductOrigin;
  year: Scalars['Int'];
  month: Scalars['Int'];
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDepositArgs = {
  deposit: CreateDepositDto;
};


export type MutationUpdateDepositArgs = {
  deposit: UpdateDepositDto;
  id: Scalars['ID'];
};


export type MutationRemoveDepositArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveAllDepositArgs = {
  id: Scalars['ID'];
};


export type MutationAddChangeRecordArgs = {
  createDepositChangeDto: CreateDepositChangeDto;
};


export type MutationReviewDepositArgs = {
  approved: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationCreateDepositChangeRecordArgs = {
  record: CreateDepositChangeDto;
};


export type MutationRemoveDepositChangeRecordArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDepositChangeRecordArgs = {
  record: UpdateDepositChangeDto;
  id: Scalars['ID'];
};


export type MutationCreatePurchaseOrderArgs = {
  purchaseOrder: CreatePurchaseOrderDto;
};


export type MutationUpdatePurchaseOrderArgs = {
  purchaseOrder: UpdatePurchaseOrderDto;
  id: Scalars['ID'];
};


export type MutationDeletePurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateReceivedPaymentArgs = {
  receivedPayment: CreateReceivedPaymentDto;
};


export type MutationLinkReceivedPaymentArgs = {
  links: Array<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationUpdateReceivedPaymentArgs = {
  receivedPayment: UpdateReceivedPaymentDto;
  id: Scalars['ID'];
};


export type MutationRemoveReceivedPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationPushMessageArgs = {
  message: Scalars['String'];
};


export type MutationCreateLogArgs = {
  log: CreateLogDto;
};


export type MutationCreateUpgradeArgs = {
  upgrade: UpgradeInputType;
};


export type MutationRemoveUpgradeArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUpgradeArgs = {
  upgrade: UpgradeInputType;
  id: Scalars['ID'];
};


export type MutationCreateRebateArgs = {
  rebate: RebateDto;
};


export type MutationUpdateRebateArgs = {
  rebate: RebateDto;
  id: Scalars['ID'];
};


export type MutationRemoveRebateArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGoogleAdsTemplateArgs = {
  googleAdsTemplate: GoogleAdsTemplateInput;
};


export type MutationRemoveGoogleAdsTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateGoogleAdsTemplateArgs = {
  googleAdsTemplate: GoogleAdsTemplateInput;
  id: Scalars['ID'];
};


export type MutationCreateGoogleAdsRecordArgs = {
  googleAdsRecord: GoogleAdsRecordInput;
};


export type MutationRemoveGoogleAdsRecordArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateGoogleAdsRecordArgs = {
  googleAdsRecord: GoogleAdsRecordInput;
  id: Scalars['ID'];
};


export type MutationUpsertMarkupSettingArgs = {
  markupSettingInput: MarkupSettingInput;
};


export type MutationRemoveMarkupSettingArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMetaAdsTemplateArgs = {
  metaAdsTemplate: MetaAdsTemplateInput;
};


export type MutationUpdateMetaAdsTemplateArgs = {
  metaAdsTemplate: MetaAdsTemplateInput;
  id: Scalars['ID'];
};


export type MutationRemoveMetaAdsTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMetaAdsRecordArgs = {
  metaAdsRecord: MetaAdsRecordInput;
};


export type MutationRemoveMetaAdsRecordArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMetaAdsRecordArgs = {
  metaAdsRecord: MetaAdsRecordInput;
  id: Scalars['ID'];
};


export type MutationCreateOrderAndItemsInJiraArgs = {
  orderId: Scalars['Float'];
};


export type MutationSyncOrderItemTasksArgs = {
  orderItemId: Scalars['Float'];
};

export type CustomerInputType = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['ID']>;
  fax?: Maybe<Scalars['String']>;
  defaultInvoiceContactId?: Maybe<Scalars['ID']>;
  defaultContactId?: Maybe<Scalars['ID']>;
  billingAddress?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['ID']>;
  invoiceMethod?: Maybe<OrderInvoiceMethods>;
  bankCode?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountName?: Maybe<Scalars['String']>;
  passbookImageUrl?: Maybe<Scalars['String']>;
  isAgent?: Maybe<Scalars['Boolean']>;
  /** Note that this is a percentage! */
  defaultDiscountPercentage?: Maybe<Scalars['Float']>;
  type?: Maybe<CustomerTypeEnum>;
  defaultPaymentDays?: Maybe<Scalars['Int']>;
  disabled?: Maybe<Scalars['Boolean']>;
  virtualAccountNumber?: Maybe<Scalars['String']>;
  contractStartDate?: Maybe<Scalars['DateTime']>;
  contractEndDate?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['Float']>;
  creditClass?: Maybe<CreditClassEnum>;
  registerDate?: Maybe<Scalars['DateTime']>;
  capital?: Maybe<Scalars['Float']>;
  answer?: Maybe<CustomerAnswerInputType>;
  answerEditable?: Maybe<Scalars['Boolean']>;
  industryTag?: Maybe<Scalars['String']>;
  productTag?: Maybe<Scalars['String']>;
  fieldTag?: Maybe<Scalars['String']>;
  invoiceExpired?: Maybe<Scalars['Boolean']>;
};

export type CustomerAnswerInputType = {
  operationType?: Maybe<Scalars['String']>;
  years?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  lastYearTransaction?: Maybe<Scalars['String']>;
  paidCredit?: Maybe<Scalars['String']>;
  negativeRecord?: Maybe<Scalars['String']>;
  bouncedRecord?: Maybe<Scalars['String']>;
};

export type AdvertiserInputType = {
  name: Scalars['String'];
  customerId?: Maybe<Scalars['ID']>;
  industryId?: Maybe<Scalars['ID']>;
  industryTag?: Maybe<Scalars['String']>;
  productTag?: Maybe<Scalars['String']>;
  fieldTag?: Maybe<Scalars['String']>;
};

export type CreateInvoiceDto = {
  orderId: Scalars['ID'];
  date: Scalars['DateTime'];
  items: Array<CreateInvoiceItemDto>;
  /** Note that this is a percentage! */
  discountPercentage?: Maybe<Scalars['Float']>;
  /** Currency used for invoice. Defaults to TWD if omitted. */
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']>;
  /** This field is only for USD invoices to enter NTD total amount */
  totalAmount?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
};

export type CreateInvoiceItemDto = {
  orderItemId: Scalars['ID'];
  amount: Scalars['Float'];
};

export type UpdateInvoiceDto = {
  postDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InvoiceStatus>;
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Note that this is a percentage! */
  discountPercentage?: Maybe<Scalars['Float']>;
  /** Currency used for invoice.  */
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']>;
  items?: Maybe<Array<UpdateInvoiceItemDto>>;
  /** 開立發票日期 */
  date?: Maybe<Scalars['DateTime']>;
  /** This field is only for USD invoices to enter NTD total amount */
  totalAmount?: Maybe<Scalars['Float']>;
  paidAmount?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateInvoiceItemDto = {
  /** If not set will create an item */
  id?: Maybe<Scalars['ID']>;
  changedAmount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  orderItemId?: Maybe<Scalars['ID']>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type SupplierInputType = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  invoiceTitle?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  productIds?: Maybe<Array<Scalars['ID']>>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ProductInputType = {
  name?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productOrigin?: Maybe<ProductOrigin>;
  supplierIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateOrderDto = {
  customerId: Scalars['ID'];
  contactId?: Maybe<Scalars['ID']>;
  invoiceContactId?: Maybe<Scalars['ID']>;
  invoiceMethod?: Maybe<OrderInvoiceMethods>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  /** Currency used for order. Defaults to TWD if omitted. */
  currency?: Maybe<CurrencyEnum>;
  /** Locale used for order. Defaults to zh-TW if omitted. */
  locale?: Maybe<LocaleEnum>;
  paymentDays?: Maybe<Scalars['Int']>;
  renewal?: Maybe<Scalars['Boolean']>;
  remark?: Maybe<Scalars['String']>;
  assignedSalesUserId?: Maybe<Scalars['ID']>;
  advertiserId?: Maybe<Scalars['ID']>;
};

export type UpdateOrderStatusDto = {
  status: OrderStatus;
  changeReason?: Maybe<Scalars['String']>;
};

export type UpdateOrderDto = {
  contactId?: Maybe<Scalars['ID']>;
  invoiceContactId?: Maybe<Scalars['ID']>;
  invoiceMethod?: Maybe<OrderInvoiceMethods>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** Currency used for order. Defaults to TWD if omitted. */
  currency?: Maybe<CurrencyEnum>;
  /** Locale used for order. Defaults to zh-TW if omitted. */
  locale?: Maybe<LocaleEnum>;
  paymentDays?: Maybe<Scalars['Int']>;
  renewal?: Maybe<Scalars['Boolean']>;
  changeReason?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  assignedSalesUserId?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  advertiserId?: Maybe<Scalars['ID']>;
};

export type CreateUserDto = {
  email: Scalars['String'];
  /** List of role ids */
  roleIds: Array<RoleIdEnum>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateUserDto = {
  email?: Maybe<Scalars['String']>;
  /** List of role ids */
  roleIds?: Maybe<Array<RoleIdEnum>>;
  name?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
};

export type ContactInputType = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
  supplierId?: Maybe<Scalars['ID']>;
};

export type CreateOrderItemDto = {
  productId: Scalars['ID'];
  orderId: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  assigneeId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  rebate?: Maybe<Scalars['Float']>;
  type?: Maybe<OrderItemType>;
  productTag?: Maybe<Scalars['String']>;
  fieldTag?: Maybe<Scalars['String']>;
  belonging?: Maybe<OrderItemBelonging>;
  supporterId?: Maybe<Scalars['ID']>;
};

export type UpdateOrderItemDto = {
  productId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  assigneeId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  rebate?: Maybe<Scalars['Float']>;
  status?: Maybe<OrderItemStatus>;
  type?: Maybe<OrderItemType>;
  productTag?: Maybe<Scalars['String']>;
  fieldTag?: Maybe<Scalars['String']>;
  belonging?: Maybe<OrderItemBelonging>;
  supporterId?: Maybe<Scalars['ID']>;
};

export type CreateDepositDto = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  orderItemId: Scalars['ID'];
  accountId?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateDepositDto = {
  status?: Maybe<DepositStatus>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['ID']>;
  accountId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type CreateDepositChangeDto = {
  depositId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateDepositChangeDto = {
  verified: Scalars['Boolean'];
  changedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
};

export type CreatePurchaseOrderDto = {
  orderItemId: Scalars['ID'];
  signedDate?: Maybe<Scalars['DateTime']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  supplierId: Scalars['ID'];
  initiatorId?: Maybe<Scalars['ID']>;
  initiateDate?: Maybe<Scalars['DateTime']>;
  gmReviewerId?: Maybe<Scalars['ID']>;
  gmReviewDate?: Maybe<Scalars['DateTime']>;
  financeReviewerId?: Maybe<Scalars['ID']>;
  financeReviewDate?: Maybe<Scalars['DateTime']>;
  managerReviewerId?: Maybe<Scalars['ID']>;
  managerReviewDate?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdatePurchaseOrderDto = {
  orderItemId?: Maybe<Scalars['ID']>;
  signedDate?: Maybe<Scalars['DateTime']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  supplierId?: Maybe<Scalars['ID']>;
  status?: Maybe<PurchaseOrderStatusEnum>;
  initiatorId?: Maybe<Scalars['ID']>;
  initiateDate?: Maybe<Scalars['DateTime']>;
  gmReviewerId?: Maybe<Scalars['ID']>;
  gmReviewDate?: Maybe<Scalars['DateTime']>;
  financeReviewerId?: Maybe<Scalars['ID']>;
  financeReviewDate?: Maybe<Scalars['DateTime']>;
  managerReviewerId?: Maybe<Scalars['ID']>;
  managerReviewDate?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
};

export type CreateReceivedPaymentDto = {
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  note?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sourceBankAccountNumber?: Maybe<Scalars['String']>;
  sourceBankCode?: Maybe<Scalars['String']>;
  destinationBankAccountNumber?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
};

export type UpdateReceivedPaymentDto = {
  amount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sourceBankAccountNumber?: Maybe<Scalars['String']>;
  sourceBankCode?: Maybe<Scalars['String']>;
  destinationBankAccountNumber?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
  status?: Maybe<ReceivedPaymentStatusEnum>;
};

export type CreateLogDto = {
  userId: Scalars['ID'];
  entityId: Scalars['ID'];
  queryEntity?: Maybe<Scalars['String']>;
  queryAction?: Maybe<Scalars['String']>;
  queryMessage?: Maybe<Scalars['String']>;
};

export type UpgradeInputType = {
  status?: Maybe<UpgradeStatus>;
  customerId?: Maybe<Scalars['ID']>;
  aeId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  lastThreeMonthAmount?: Maybe<Scalars['Float']>;
  targetCreditClass?: Maybe<CreditClassEnum>;
  financeId?: Maybe<Scalars['ID']>;
  receivableAmount?: Maybe<Scalars['Float']>;
  unrecordedAmount?: Maybe<Scalars['Float']>;
  overdue?: Maybe<Scalars['Boolean']>;
  financeComment?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['ID']>;
  managerComment?: Maybe<Scalars['String']>;
  gmId?: Maybe<Scalars['ID']>;
  gmComment?: Maybe<Scalars['String']>;
};

export type RebateDto = {
  customerId?: Maybe<Scalars['ID']>;
  productIds?: Maybe<Array<Scalars['ID']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  intervals?: Maybe<Array<IntervalDto>>;
  type?: Maybe<OrderItemType>;
};

export type IntervalDto = {
  rebateId?: Maybe<Scalars['ID']>;
  lowerBound?: Maybe<Scalars['Float']>;
  upperBound?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

export type GoogleAdsTemplateInput = {
  customerId?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  adTypes?: Maybe<Array<Scalars['String']>>;
  reportNames?: Maybe<Array<Scalars['String']>>;
  removeUnexposed?: Maybe<Scalars['Boolean']>;
  metricsFields?: Maybe<Array<Scalars['String']>>;
  accountCampaigns?: Maybe<Scalars['JSON']>;
  assigneeId?: Maybe<Scalars['ID']>;
};

export type GoogleAdsRecordInput = {
  templateId: Scalars['Float'];
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  assigneeId?: Maybe<Scalars['ID']>;
};

export type MarkupSettingInput = {
  accountId?: Maybe<Scalars['String']>;
  adType?: Maybe<Scalars['String']>;
  defaultMarkup?: Maybe<Scalars['Int']>;
  markupMap?: Maybe<Scalars['JSON']>;
};

export type MetaAdsTemplateInput = {
  customerId?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  accountCampaigns?: Maybe<Scalars['JSON']>;
  reportNames?: Maybe<Array<Scalars['String']>>;
  metricsFields?: Maybe<Array<Scalars['String']>>;
  assigneeId?: Maybe<Scalars['ID']>;
  removeUnexposed?: Maybe<Scalars['Boolean']>;
};

export type MetaAdsRecordInput = {
  templateId: Scalars['Float'];
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  assigneeId?: Maybe<Scalars['ID']>;
};

export type MeQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQueryQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'picture'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type NoopMutationVariables = Exact<{ [key: string]: never; }>;


export type NoopMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'noop'>
);

export type RemoveFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeFile'>
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'picture'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type GetNotificationListQueryVariables = Exact<{
  ordersFiltering: Array<FilteringInputType>;
  invoicesFiltering: Array<FilteringInputType>;
}>;


export type GetNotificationListQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'PaginatedOrderResponse' }
    & Pick<PaginatedOrderResponse, 'total'>
  ), invoices: (
    { __typename?: 'PaginatedInvoiceResponse' }
    & Pick<PaginatedInvoiceResponse, 'total'>
  ) }
);

export type GetPurchaseOrderNotificationQueryVariables = Exact<{
  purchaseOrdersFiltering: Array<FilteringInputType>;
}>;


export type GetPurchaseOrderNotificationQuery = (
  { __typename?: 'Query' }
  & { purchaseOrders: (
    { __typename?: 'PaginatedPurchaseOrderResponse' }
    & Pick<PaginatedPurchaseOrderResponse, 'total'>
  ) }
);

export type PushMessageMutationVariables = Exact<{
  message: Scalars['String'];
}>;


export type PushMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pushMessage'>
);

export type CreateAdvertiserMutationVariables = Exact<{
  advertiser: AdvertiserInputType;
}>;


export type CreateAdvertiserMutation = (
  { __typename?: 'Mutation' }
  & { createAdvertiser: (
    { __typename?: 'Advertiser' }
    & Pick<Advertiser, 'id' | 'name'>
  ) }
);

export type RemoveAdvertiserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveAdvertiserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAdvertiser'>
);

export type UpdateAdvertiserMutationVariables = Exact<{
  advertiser: AdvertiserInputType;
  id: Scalars['ID'];
}>;


export type UpdateAdvertiserMutation = (
  { __typename?: 'Mutation' }
  & { updateAdvertiser: (
    { __typename?: 'Advertiser' }
    & Pick<Advertiser, 'id' | 'name'>
  ) }
);

export type CreateContactMutationVariables = Exact<{
  contact: ContactInputType;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name'>
  ) }
);

export type RemoveContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeContact'>
);

export type UpdateContactMutationVariables = Exact<{
  contact: ContactInputType;
  id: Scalars['ID'];
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name'>
  ) }
);

export type CreateCustomerMutationVariables = Exact<{
  customer: CustomerInputType;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'phone' | 'address' | 'billingAddress' | 'taxId' | 'invoiceMethod' | 'bankCode' | 'bankAccountNumber' | 'bankAccountName' | 'passbookImageUrl' | 'isAgent'>
    & { industry?: Maybe<(
      { __typename?: 'Industry' }
      & Pick<Industry, 'id' | 'name'>
    )> }
  ) }
);

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'address' | 'bankAccountName' | 'bankAccountNumber' | 'bankCode' | 'billingAddress' | 'taxId' | 'createdAt' | 'id' | 'invoiceMethod' | 'isAgent' | 'name' | 'passbookImageUrl' | 'phone' | 'updatedAt' | 'fax' | 'defaultDiscountPercentage' | 'type' | 'defaultPaymentDays' | 'disabled' | 'virtualAccountNumber' | 'contractStartDate' | 'contractEndDate' | 'creditClass' | 'registerDate' | 'capital' | 'answerEditable' | 'industryTag' | 'productTag' | 'fieldTag' | 'score' | 'invoiceExpired'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'phone' | 'email'>
    )>, industry?: Maybe<(
      { __typename?: 'Industry' }
      & Pick<Industry, 'id' | 'name'>
    )>, orders: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'number'>
    )>, assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, advertisers: Array<(
      { __typename?: 'Advertiser' }
      & Pick<Advertiser, 'id' | 'name' | 'industryTag' | 'productTag' | 'fieldTag'>
      & { industry?: Maybe<(
        { __typename?: 'Industry' }
        & Pick<Industry, 'id' | 'name'>
      )> }
    )>, invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'status' | 'invoiceNumber' | 'postDate'>
    )>, contractFiles: Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'mimetype' | 'createdAt' | 'uri'>
    )>, answer?: Maybe<(
      { __typename?: 'CustomerAnswerEntity' }
      & Pick<CustomerAnswerEntity, 'operationType' | 'years' | 'capital' | 'lastYearTransaction' | 'paidCredit' | 'negativeRecord' | 'bouncedRecord'>
    )>, rebates: Array<(
      { __typename?: 'Rebate' }
      & Pick<Rebate, 'id' | 'startDate' | 'endDate' | 'type'>
      & { products: Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'productOrigin'>
      )>, intervals: Array<(
        { __typename?: 'Interval' }
        & Pick<Interval, 'id' | 'lowerBound' | 'upperBound' | 'rate'>
      )> }
    )> }
  )> }
);

export type GetCustomerBriefListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerBriefListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedCustomerResponse' }
    & Pick<PaginatedCustomerResponse, 'total'>
    & { list: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'taxId' | 'phone' | 'isAgent' | 'address' | 'billingAddress' | 'bankCode' | 'bankAccountNumber' | 'invoiceMethod' | 'defaultPaymentDays' | 'type'>
      & { industry?: Maybe<(
        { __typename?: 'Industry' }
        & Pick<Industry, 'id' | 'name'>
      )>, advertisers: Array<(
        { __typename?: 'Advertiser' }
        & Pick<Advertiser, 'id' | 'name'>
      )>, contacts: Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'email' | 'name'>
      )> }
    )> }
  ) }
);

export type GetCustomerListQueryVariables = Exact<{
  pagination?: Maybe<PaginationInputType>;
  filtering: Array<FilteringInputType>;
  ordering?: Maybe<OrderingInputType>;
}>;


export type GetCustomerListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedCustomerResponse' }
    & Pick<PaginatedCustomerResponse, 'total'>
    & { list: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'taxId' | 'phone' | 'isAgent' | 'address' | 'billingAddress' | 'bankCode' | 'bankAccountNumber' | 'contractEndDate' | 'industryTag' | 'productTag' | 'fieldTag' | 'score' | 'disabled'>
      & { industry?: Maybe<(
        { __typename?: 'Industry' }
        & Pick<Industry, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type GetIndustryListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIndustryListQuery = (
  { __typename?: 'Query' }
  & { industries: Array<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )> }
);

export type RemoveCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCustomer'>
);

export type UpdateCustomerMutationVariables = Exact<{
  customer: CustomerInputType;
  id: Scalars['ID'];
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  ) }
);

export type ActiveDepositsQueryVariables = Exact<{
  pagination: PaginationInputType;
  filtering: Array<FilteringInputType>;
  ordering: OrderingInputType;
}>;


export type ActiveDepositsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedDepositResponse' }
    & { list: Array<(
      { __typename?: 'Deposit' }
      & Pick<Deposit, 'createdAt' | 'amount' | 'status' | 'id'>
      & { orderItem: (
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'totalAmount'>
      ) }
    )> }
  ) }
);

export type AddChangeRecordMutationVariables = Exact<{
  createDepositChangeDto: CreateDepositChangeDto;
}>;


export type AddChangeRecordMutation = (
  { __typename?: 'Mutation' }
  & { addChangeRecord: (
    { __typename?: 'Deposit' }
    & Pick<Deposit, 'id'>
  ) }
);

export type CreateDepositMutationVariables = Exact<{
  deposit: CreateDepositDto;
}>;


export type CreateDepositMutation = (
  { __typename?: 'Mutation' }
  & { createDeposit: (
    { __typename?: 'Deposit' }
    & Pick<Deposit, 'id' | 'amount'>
  ) }
);

export type GetDepositListQueryVariables = Exact<{
  pagination: PaginationInputType;
  filtering: Array<FilteringInputType>;
  ordering: OrderingInputType;
}>;


export type GetDepositListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedDepositResponse' }
    & Pick<PaginatedDepositResponse, 'total'>
    & { list: Array<(
      { __typename?: 'Deposit' }
      & Pick<Deposit, 'id' | 'amount' | 'status' | 'createdAt' | 'updatedAt' | 'accountId' | 'startDate' | 'endDate' | 'description' | 'note'>
      & { orderItem: (
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id'>
        & { order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'number'>
          & { customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'id' | 'name'>
          ), assignedSalesUser?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        ), assignee?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name'>
        )> }
      ), supplier?: Maybe<(
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'name'>
      )>, changes: Array<(
        { __typename?: 'DepositChangeRecord' }
        & Pick<DepositChangeRecord, 'id' | 'amount' | 'createdAt' | 'verifiedAt' | 'verified' | 'description' | 'startDate' | 'endDate'>
      )> }
    )> }
  ) }
);

export type RemoveAllDepositMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveAllDepositMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAllDeposit'>
);

export type RemoveDepositMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveDepositMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDeposit'>
);

export type ReviewDepositMutationVariables = Exact<{
  id: Scalars['ID'];
  approved: Scalars['Boolean'];
}>;


export type ReviewDepositMutation = (
  { __typename?: 'Mutation' }
  & { reviewDeposit: (
    { __typename?: 'Deposit' }
    & Pick<Deposit, 'id'>
  ) }
);

export type UpdateDepositMutationVariables = Exact<{
  deposit: UpdateDepositDto;
  id: Scalars['ID'];
}>;


export type UpdateDepositMutation = (
  { __typename?: 'Mutation' }
  & { updateDeposit: (
    { __typename?: 'Deposit' }
    & Pick<Deposit, 'id' | 'amount'>
  ) }
);

export type GetMarkupSettingQueryVariables = Exact<{
  accountId: Scalars['String'];
  adType: Scalars['String'];
}>;


export type GetMarkupSettingQuery = (
  { __typename?: 'Query' }
  & { getMarkupSettingByAccountAndType?: Maybe<(
    { __typename?: 'MarkupSetting' }
    & Pick<MarkupSetting, 'id' | 'accountId' | 'adType' | 'defaultMarkup' | 'markupMap'>
  )> }
);

export type UpsertMarkupSettingMutationVariables = Exact<{
  markupSettingInput: MarkupSettingInput;
}>;


export type UpsertMarkupSettingMutation = (
  { __typename?: 'Mutation' }
  & { upsertMarkupSetting: (
    { __typename?: 'MarkupSetting' }
    & Pick<MarkupSetting, 'id' | 'accountId' | 'adType' | 'defaultMarkup' | 'markupMap'>
  ) }
);

export type CreateGoogleAdsRecordMutationVariables = Exact<{
  googleAdsRecordInput: GoogleAdsRecordInput;
}>;


export type CreateGoogleAdsRecordMutation = (
  { __typename?: 'Mutation' }
  & { createGoogleAdsRecord: (
    { __typename?: 'GoogleAdsRecord' }
    & Pick<GoogleAdsRecord, 'id'>
  ) }
);

export type CreateMetaAdsRecordMutationVariables = Exact<{
  metaAdsRecordInput: MetaAdsRecordInput;
}>;


export type CreateMetaAdsRecordMutation = (
  { __typename?: 'Mutation' }
  & { createMetaAdsRecord: (
    { __typename?: 'MetaAdsRecord' }
    & Pick<MetaAdsRecord, 'id'>
  ) }
);

export type GetGoogleAdsRecordListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGoogleAdsRecordListQuery = (
  { __typename?: 'Query' }
  & { googleAdsRecords: Array<(
    { __typename?: 'GoogleAdsRecord' }
    & Pick<GoogleAdsRecord, 'id' | 'name' | 'startDate' | 'endDate' | 'createdAt' | 'status'>
    & { assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )> }
);

export type GetMetaAdsRecordListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMetaAdsRecordListQuery = (
  { __typename?: 'Query' }
  & { metaAdsRecords: Array<(
    { __typename?: 'MetaAdsRecord' }
    & Pick<MetaAdsRecord, 'id' | 'name' | 'startDate' | 'endDate' | 'createdAt' | 'status'>
    & { assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveGoogleAdsRecordMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveGoogleAdsRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGoogleAdsRecord'>
);

export type RemoveMetaAdsRecordMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMetaAdsRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeMetaAdsRecord'>
);

export type CreateGoogleAdsTemplateMutationVariables = Exact<{
  googleAdsTemplateInput: GoogleAdsTemplateInput;
}>;


export type CreateGoogleAdsTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createGoogleAdsTemplate: (
    { __typename?: 'GoogleAdsTemplate' }
    & Pick<GoogleAdsTemplate, 'id'>
  ) }
);

export type GetAdsAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdsAccountsQuery = (
  { __typename?: 'Query' }
  & { adsAccounts: Array<(
    { __typename?: 'GoogleAdsAccount' }
    & Pick<GoogleAdsAccount, 'name' | 'id' | 'isManager' | 'level'>
  )> }
);

export type CampaignsQueryVariables = Exact<{
  cid: Scalars['ID'];
}>;


export type CampaignsQuery = (
  { __typename?: 'Query' }
  & { campaigns: Array<(
    { __typename?: 'GoogleAdsCampaign' }
    & Pick<GoogleAdsCampaign, 'id' | 'name'>
  )> }
);

export type GetGoogleAdsTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGoogleAdsTemplatesQuery = (
  { __typename?: 'Query' }
  & { googleAdsTemplates: Array<(
    { __typename?: 'GoogleAdsTemplate' }
    & Pick<GoogleAdsTemplate, 'id' | 'name' | 'accountCampaigns' | 'adTypes' | 'reportNames' | 'removeUnexposed' | 'metricsFields'>
    & { assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveGoogleAdsTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveGoogleAdsTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGoogleAdsTemplate'>
);

export type UpdateGoogleAdsTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  googleAdsTemplateInput: GoogleAdsTemplateInput;
}>;


export type UpdateGoogleAdsTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateGoogleAdsTemplate: (
    { __typename?: 'GoogleAdsTemplate' }
    & Pick<GoogleAdsTemplate, 'id'>
  ) }
);

export type CreateMetaAdsTemplateMutationVariables = Exact<{
  metaAdsTemplateInput: MetaAdsTemplateInput;
}>;


export type CreateMetaAdsTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createMetaAdsTemplate: (
    { __typename?: 'MetaAdsTemplate' }
    & Pick<MetaAdsTemplate, 'id'>
  ) }
);

export type GetMetaAdsAccountListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMetaAdsAccountListQuery = (
  { __typename?: 'Query' }
  & { metaAdsAccounts: Array<(
    { __typename?: 'MetaAdsAccount' }
    & Pick<MetaAdsAccount, 'id' | 'name'>
  )> }
);

export type GetMetaCampaignListQueryVariables = Exact<{
  cid: Scalars['String'];
}>;


export type GetMetaCampaignListQuery = (
  { __typename?: 'Query' }
  & { metaAdsCampaigns: Array<(
    { __typename?: 'MetaAdsCampaign' }
    & Pick<MetaAdsCampaign, 'id' | 'name'>
  )> }
);

export type GetMetaTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMetaTemplatesQuery = (
  { __typename?: 'Query' }
  & { metaAdsTemplates: Array<(
    { __typename?: 'MetaAdsTemplate' }
    & Pick<MetaAdsTemplate, 'id' | 'name' | 'accountCampaigns' | 'reportNames' | 'metricsFields' | 'removeUnexposed'>
    & { assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveMetaAdsTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMetaAdsTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeMetaAdsTemplate'>
);

export type UpdateMetaAdsTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  metaAdsTemplateInput: MetaAdsTemplateInput;
}>;


export type UpdateMetaAdsTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateMetaAdsTemplate: (
    { __typename?: 'MetaAdsTemplate' }
    & Pick<MetaAdsTemplate, 'id'>
  ) }
);

export type CreateInvoicesMutationVariables = Exact<{
  invoices: Array<CreateInvoiceDto>;
}>;


export type CreateInvoicesMutation = (
  { __typename?: 'Mutation' }
  & { createInvoices: Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'createdAt' | 'status' | 'postDate' | 'date'>
  )> }
);

export type GetInvoiceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'postDate' | 'createdAt' | 'date' | 'status' | 'discountPercentage' | 'currency' | 'description' | 'invoiceNumber' | 'totalAmount' | 'paidAmount' | 'note' | 'creditDate' | 'expired'>
    & { order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'name' | 'number'>
      & { customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'defaultDiscountPercentage'>
      ), assignedSalesUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    ), items: Array<(
      { __typename?: 'InvoiceItem' }
      & Pick<InvoiceItem, 'id' | 'amount' | 'name' | 'changedAmount'>
      & { orderItem: (
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'totalAmount'>
      ) }
    )> }
  )> }
);

export type GetInvoiceListQueryVariables = Exact<{
  pagination: PaginationInputType;
  filtering: Array<FilteringInputType>;
  ordering: OrderingInputType;
}>;


export type GetInvoiceListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedInvoiceResponse' }
    & Pick<PaginatedInvoiceResponse, 'total'>
    & { list: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'date' | 'postDate' | 'createdAt' | 'status' | 'invoiceNumber' | 'expired'>
      & { order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'name' | 'number'>
      ) }
    )> }
  ) }
);

export type GetInvoiceNotificationQueryVariables = Exact<{
  invoicesFiltering: Array<FilteringInputType>;
}>;


export type GetInvoiceNotificationQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'PaginatedInvoiceResponse' }
    & Pick<PaginatedInvoiceResponse, 'total'>
  ) }
);

export type RemoveInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeInvoice'>
);

export type UpdateInvoiceMutationVariables = Exact<{
  invoice: UpdateInvoiceDto;
  id: Scalars['ID'];
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'date' | 'postDate' | 'status'>
  ) }
);

export type CreateLogMutationVariables = Exact<{
  log: CreateLogDto;
}>;


export type CreateLogMutation = (
  { __typename?: 'Mutation' }
  & { createLog: (
    { __typename?: 'Log' }
    & Pick<Log, 'id'>
  ) }
);

export type GetLogListQueryVariables = Exact<{
  pagination?: Maybe<PaginationInputType>;
  filtering: Array<FilteringInputType>;
  ordering?: Maybe<OrderingInputType>;
}>;


export type GetLogListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedLogResponse' }
    & Pick<PaginatedLogResponse, 'total'>
    & { list: Array<(
      { __typename?: 'Log' }
      & Pick<Log, 'id' | 'entityId' | 'queryEntity' | 'queryAction' | 'queryMessage' | 'createdAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type CreateOrderMutationVariables = Exact<{
  order: CreateOrderDto;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'number'>
  ) }
);

export type GetOrderDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrderDataQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
    & { suppliers?: Maybe<Array<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    )>> }
  )> }
);

export type GetOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'number' | 'name' | 'createdAt' | 'status' | 'invoiceMethod' | 'currency' | 'locale' | 'paymentDays' | 'renewal' | 'changeReason' | 'remark'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'email' | 'phone'>
    )>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'type' | 'industryTag' | 'productTag' | 'fieldTag'>
      & { contacts: Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'name'>
      )>, advertisers: Array<(
        { __typename?: 'Advertiser' }
        & Pick<Advertiser, 'id' | 'name'>
      )> }
    ), files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'mimetype' | 'createdAt' | 'uri'>
    )>, items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'type' | 'totalAmount' | 'serviceFee' | 'budget' | 'id' | 'description' | 'startDate' | 'endDate' | 'status' | 'productTag' | 'fieldTag' | 'belonging'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )>, deposits: Array<(
        { __typename?: 'Deposit' }
        & Pick<Deposit, 'id' | 'amount' | 'status' | 'createdAt' | 'startDate' | 'endDate'>
      )>, assignee?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )>, supporter?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )>, invoiceContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'email' | 'phone'>
    )>, invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'invoiceNumber' | 'postDate' | 'createdAt' | 'status' | 'date' | 'note'>
      & { items: Array<(
        { __typename?: 'InvoiceItem' }
        & Pick<InvoiceItem, 'id' | 'name' | 'amount' | 'changedAmount'>
        & { orderItem: (
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id'>
        ) }
      )> }
    )>, assignedSalesUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, advertiser?: Maybe<(
      { __typename?: 'Advertiser' }
      & Pick<Advertiser, 'id' | 'name' | 'industryTag' | 'productTag' | 'fieldTag'>
    )> }
  )> }
);

export type GetOrdersQueryVariables = Exact<{
  pagination: PaginationInputType;
  filtering: Array<FilteringInputType>;
  ordering: OrderingInputType;
}>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedOrderResponse' }
    & Pick<PaginatedOrderResponse, 'total'>
    & { list: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'number' | 'name' | 'createdAt' | 'updatedAt' | 'status'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'name' | 'id' | 'phone' | 'email'>
      )>, customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'name' | 'id' | 'type'>
      ), assignedSalesUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type RemoveOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOrder'>
);

export type UpdateOrderMutationVariables = Exact<{
  order: UpdateOrderDto;
  id: Scalars['ID'];
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'number'>
  ) }
);

export type UpdateOrderStatusMutationVariables = Exact<{
  status: UpdateOrderStatusDto;
  id: Scalars['ID'];
}>;


export type UpdateOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderStatus: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'number' | 'changeReason'>
  ) }
);

export type CreateOrderItemMutationVariables = Exact<{
  orderItem: CreateOrderItemDto;
}>;


export type CreateOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { createOrderItem: (
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'description'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )> }
  ) }
);

export type GetOrderItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrderItemQuery = (
  { __typename?: 'Query' }
  & { orderItem?: Maybe<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'type' | 'totalAmount' | 'serviceFee' | 'budget' | 'id' | 'description' | 'startDate' | 'endDate' | 'status' | 'productTag' | 'fieldTag' | 'belonging'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'productOrigin'>
    )>, deposits: Array<(
      { __typename?: 'Deposit' }
      & Pick<Deposit, 'id' | 'amount' | 'status' | 'createdAt' | 'accountId' | 'startDate' | 'endDate' | 'description'>
      & { supplier?: Maybe<(
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'name'>
      )>, accountCosts: Array<(
        { __typename?: 'AccountCost' }
        & Pick<AccountCost, 'id' | 'cost' | 'date'>
      )>, changes: Array<(
        { __typename?: 'DepositChangeRecord' }
        & Pick<DepositChangeRecord, 'id' | 'amount' | 'createdAt' | 'verifiedAt' | 'verified' | 'description' | 'startDate' | 'endDate'>
      )> }
    )>, order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'name' | 'status'>
      & { invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'status'>
        & { items: Array<(
          { __typename?: 'InvoiceItem' }
          & Pick<InvoiceItem, 'amount' | 'name'>
          & { orderItem: (
            { __typename?: 'OrderItem' }
            & Pick<OrderItem, 'id'>
          ) }
        )> }
      )>, customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'invoiceExpired'>
      ) }
    ), assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, purchaseOrders: Array<(
      { __typename?: 'PurchaseOrder' }
      & Pick<PurchaseOrder, 'id' | 'createdAt' | 'signedDate' | 'status' | 'paidDate' | 'amount'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'name' | 'email'>
      )>, supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'name'>
      ) }
    )>, supporter?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )> }
);

export type GetOrderItemListQueryVariables = Exact<{
  pagination?: Maybe<PaginationInputType>;
  filtering: Array<FilteringInputType>;
  ordering?: Maybe<OrderingInputType>;
}>;


export type GetOrderItemListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedOrderItemResponse' }
    & Pick<PaginatedOrderItemResponse, 'total'>
    & { list: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'type' | 'status' | 'startDate' | 'endDate' | 'totalAmount' | 'serviceFee' | 'budget' | 'productTag' | 'fieldTag' | 'belonging'>
      & { order: (
        { __typename?: 'Order' }
        & Pick<Order, 'number' | 'name'>
        & { assignedSalesUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name'>
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'name'>
        ), invoices: Array<(
          { __typename?: 'Invoice' }
          & { items: Array<(
            { __typename?: 'InvoiceItem' }
            & Pick<InvoiceItem, 'amount'>
            & { orderItem: (
              { __typename?: 'OrderItem' }
              & Pick<OrderItem, 'id'>
            ) }
          )> }
        )> }
      ), product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'name' | 'productOrigin'>
      )>, deposits: Array<(
        { __typename?: 'Deposit' }
        & Pick<Deposit, 'id' | 'amount' | 'accountId' | 'startDate' | 'endDate'>
        & { supplier?: Maybe<(
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'name'>
        )>, accountCosts: Array<(
          { __typename?: 'AccountCost' }
          & Pick<AccountCost, 'cost' | 'date'>
        )> }
      )>, assignee?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )>, invoiceItems?: Maybe<Array<(
        { __typename?: 'InvoiceItem' }
        & Pick<InvoiceItem, 'amount'>
      )>>, supporter?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type DeleteOrderItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrderItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrderItem'>
);

export type UpdateOrderItemMutationVariables = Exact<{
  orderItem: UpdateOrderItemDto;
  id: Scalars['ID'];
}>;


export type UpdateOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderItem: (
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'description'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )> }
  ) }
);

export type CreateProductMutationVariables = Exact<{
  product: ProductInputType;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  ) }
);

export type GetProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'website' | 'createdAt' | 'description' | 'productOrigin'>
    & { suppliers?: Maybe<Array<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name' | 'address' | 'invoiceTitle' | 'taxId'>
      & { contacts: Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'name'>
      )> }
    )>> }
  ) }
);

export type GetProductListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductListQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'website' | 'createdAt' | 'description' | 'productOrigin'>
    & { suppliers?: Maybe<Array<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    )>> }
  )> }
);

export type RemoveProductMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProduct'>
);

export type UpdateProductMutationVariables = Exact<{
  product: ProductInputType;
  id: Scalars['ID'];
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  ) }
);

export type CreatePurchaseOrderMutationVariables = Exact<{
  purchaseOrder: CreatePurchaseOrderDto;
}>;


export type CreatePurchaseOrderMutation = (
  { __typename?: 'Mutation' }
  & { createPurchaseOrder: (
    { __typename?: 'PurchaseOrder' }
    & Pick<PurchaseOrder, 'id' | 'amount'>
  ) }
);

export type GetPurchaseOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { purchaseOrder: (
    { __typename?: 'PurchaseOrder' }
    & Pick<PurchaseOrder, 'id' | 'status' | 'createdAt' | 'updatedAt' | 'signedDate' | 'paidDate' | 'amount' | 'description' | 'initiateDate' | 'managerReviewDate' | 'gmReviewDate' | 'financeReviewDate' | 'note'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'phone' | 'email'>
    )>, orderItem: (
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id'>
      )>, order: (
        { __typename?: 'Order' }
        & { assignedSalesUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name'>
        )> }
      ) }
    ), supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
      & { contacts: Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'name'>
      )> }
    ), files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'mimetype' | 'createdAt' | 'uri'>
    )>, invoiceFiles: Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'mimetype' | 'createdAt' | 'uri'>
    )>, initiator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, managerReviewer?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, gmReviewer?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, financeReviewer?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  ) }
);

export type GetPurchaseOrderListQueryVariables = Exact<{
  pagination: PaginationInputType;
  filtering: Array<FilteringInputType>;
  ordering: OrderingInputType;
}>;


export type GetPurchaseOrderListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedPurchaseOrderResponse' }
    & Pick<PaginatedPurchaseOrderResponse, 'total'>
    & { list: Array<(
      { __typename?: 'PurchaseOrder' }
      & Pick<PurchaseOrder, 'id' | 'status' | 'createdAt' | 'paidDate'>
      & { supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'name'>
      ), orderItem: (
        { __typename?: 'OrderItem' }
        & { order: (
          { __typename?: 'Order' }
          & Pick<Order, 'number'>
          & { assignedSalesUser?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        ) }
      ), initiator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type RemovePurchaseOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemovePurchaseOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePurchaseOrder'>
);

export type UpdatePurchaseOrderMutationVariables = Exact<{
  purchaseOrder: UpdatePurchaseOrderDto;
  id: Scalars['ID'];
}>;


export type UpdatePurchaseOrderMutation = (
  { __typename?: 'Mutation' }
  & { updatePurchaseOrder: (
    { __typename?: 'PurchaseOrder' }
    & Pick<PurchaseOrder, 'id' | 'status'>
  ) }
);

export type CreateRebateMutationVariables = Exact<{
  rebate: RebateDto;
}>;


export type CreateRebateMutation = (
  { __typename?: 'Mutation' }
  & { createRebate: (
    { __typename?: 'Rebate' }
    & Pick<Rebate, 'id'>
  ) }
);

export type RemoveRebateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveRebateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRebate'>
);

export type UpdateRebateMutationVariables = Exact<{
  id: Scalars['ID'];
  rebate: RebateDto;
}>;


export type UpdateRebateMutation = (
  { __typename?: 'Mutation' }
  & { updateRebate: (
    { __typename?: 'Rebate' }
    & Pick<Rebate, 'id'>
  ) }
);

export type CreateReceivedPaymentMutationVariables = Exact<{
  receivedPayment: CreateReceivedPaymentDto;
}>;


export type CreateReceivedPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createReceivedPayment: (
    { __typename?: 'ReceivedPayment' }
    & Pick<ReceivedPayment, 'id' | 'amount'>
  ) }
);

export type GetReceivedPaymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetReceivedPaymentQuery = (
  { __typename?: 'Query' }
  & { receivedPayment: (
    { __typename?: 'ReceivedPayment' }
    & Pick<ReceivedPayment, 'id' | 'createdAt' | 'updatedAt' | 'amount' | 'date' | 'note' | 'remark' | 'status' | 'sourceBankCode' | 'sourceBankAccountNumber' | 'destinationBankAccountNumber'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
      & { invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'status' | 'invoiceNumber' | 'postDate'>
        & { items: Array<(
          { __typename?: 'InvoiceItem' }
          & Pick<InvoiceItem, 'id' | 'amount'>
        )> }
      )> }
    )>, invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'status' | 'invoiceNumber' | 'postDate'>
      & { items: Array<(
        { __typename?: 'InvoiceItem' }
        & Pick<InvoiceItem, 'id' | 'amount'>
      )> }
    )> }
  ) }
);

export type GetReceivedPaymentListQueryVariables = Exact<{
  pagination: PaginationInputType;
  filtering: Array<FilteringInputType>;
  ordering: OrderingInputType;
}>;


export type GetReceivedPaymentListQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedReceivedPaymentResponse' }
    & Pick<PaginatedReceivedPaymentResponse, 'total'>
    & { list: Array<(
      { __typename?: 'ReceivedPayment' }
      & Pick<ReceivedPayment, 'id' | 'createdAt' | 'updatedAt' | 'amount' | 'date' | 'note' | 'remark' | 'status' | 'sourceBankAccountNumber' | 'destinationBankAccountNumber'>
      & { customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name'>
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id'>
      )> }
    )> }
  ) }
);

export type LinkReceivedPaymentMutationVariables = Exact<{
  links: Array<Scalars['ID']>;
  id: Scalars['ID'];
}>;


export type LinkReceivedPaymentMutation = (
  { __typename?: 'Mutation' }
  & { linkReceivedPayment: (
    { __typename?: 'ReceivedPayment' }
    & Pick<ReceivedPayment, 'id' | 'status'>
    & { invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'status'>
    )> }
  ) }
);

export type RemoveReceivedPaymentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveReceivedPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeReceivedPayment'>
);

export type UpdateReceivedPaymentMutationVariables = Exact<{
  receivedPayment: UpdateReceivedPaymentDto;
  id: Scalars['ID'];
}>;


export type UpdateReceivedPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateReceivedPayment: (
    { __typename?: 'ReceivedPayment' }
    & Pick<ReceivedPayment, 'id' | 'status'>
  ) }
);

export type GetOrderItemReportQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type GetOrderItemReportQuery = (
  { __typename?: 'Query' }
  & { orderItemReport: Array<(
    { __typename?: 'OrderItemReportRow' }
    & Pick<OrderItemReportRow, 'id' | 'budget' | 'accumulatedCosts' | 'accumulatedOutstandingAmount' | 'currentMonthCosts' | 'currentMonthOutstandingAmount' | 'overSpendAmount' | 'overSpendCostRatio' | 'overSpendRatio' | 'accountIds' | 'type' | 'endDate' | 'estimatedCostPercentage' | 'estimatedProfit' | 'estimatedProfitPercentage' | 'markup' | 'markupPercentage' | 'name' | 'orderNumber' | 'previousCosts' | 'previouslyOutstandingAmount' | 'previousIncome' | 'currentMonthIncome' | 'accumulatedIncome' | 'serviceFee' | 'serviceFeePercentage' | 'startDate' | 'summarizedTotal' | 'totalAmount'>
  )> }
);

export type RealAccountCostsQueryVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
  productOrigin: ProductOrigin;
}>;


export type RealAccountCostsQuery = (
  { __typename?: 'Query' }
  & { realAccountCosts: Array<(
    { __typename?: 'RealAccountCost' }
    & Pick<RealAccountCost, 'id' | 'cost' | 'month' | 'year' | 'accountId' | 'productOrigin'>
  )> }
);

export type RemoveRealAccountCostsMutationVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
  productOrigin: ProductOrigin;
}>;


export type RemoveRealAccountCostsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRealAccountCosts'>
);

export type CreateSupplierMutationVariables = Exact<{
  supplier: SupplierInputType;
}>;


export type CreateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createSupplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'address' | 'invoiceTitle' | 'taxId'>
  ) }
);

export type GetSupplierQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSupplierQuery = (
  { __typename?: 'Query' }
  & { supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'phone' | 'address' | 'invoiceTitle' | 'taxId' | 'bankCode' | 'bankAccountNumber'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'website' | 'createdAt' | 'description' | 'productOrigin'>
    )>, contacts: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'email' | 'phone'>
    )> }
  ) }
);

export type GetSupplierListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupplierListQuery = (
  { __typename?: 'Query' }
  & { suppliers: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'phone' | 'address' | 'invoiceTitle' | 'taxId' | 'bankCode' | 'bankAccountNumber'>
  )> }
);

export type RemoveSupplierMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSupplier'>
);

export type UpdateSupplierMutationVariables = Exact<{
  supplier: SupplierInputType;
  id: Scalars['ID'];
}>;


export type UpdateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  ) }
);

export type CreateUpgradeMutationVariables = Exact<{
  upgrade: UpgradeInputType;
}>;


export type CreateUpgradeMutation = (
  { __typename?: 'Mutation' }
  & { createUpgrade: (
    { __typename?: 'UpgradeEntity' }
    & Pick<UpgradeEntity, 'id'>
  ) }
);

export type GetUpgradeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUpgradeQuery = (
  { __typename?: 'Query' }
  & { upgrade: (
    { __typename?: 'UpgradeEntity' }
    & Pick<UpgradeEntity, 'id' | 'createdAt' | 'updatedAt' | 'status' | 'description' | 'lastThreeMonthAmount' | 'targetCreditClass' | 'receivableAmount' | 'unrecordedAmount' | 'overdue' | 'financeComment' | 'managerComment' | 'gmComment'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'creditClass'>
    )>, ae?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, finance?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, manager?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, gm?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  ) }
);

export type GetUpgradesQueryVariables = Exact<{
  pagination: PaginationInputType;
  filtering: Array<FilteringInputType>;
  ordering: OrderingInputType;
}>;


export type GetUpgradesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'PaginatedUpgradeEntityResponse' }
    & Pick<PaginatedUpgradeEntityResponse, 'total'>
    & { list: Array<(
      { __typename?: 'UpgradeEntity' }
      & Pick<UpgradeEntity, 'id' | 'createdAt' | 'updatedAt' | 'status' | 'description' | 'targetCreditClass'>
      & { customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name'>
      )>, ae?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type GetUpgradesBriefQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUpgradesBriefQuery = (
  { __typename?: 'Query' }
  & { upgradeList: Array<(
    { __typename?: 'UpgradeEntity' }
    & Pick<UpgradeEntity, 'id' | 'status'>
  )> }
);

export type RemoveUpgradeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUpgradeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUpgrade'>
);

export type UpdateUpgradeMutationVariables = Exact<{
  upgrade: UpgradeInputType;
  id: Scalars['ID'];
}>;


export type UpdateUpgradeMutation = (
  { __typename?: 'Mutation' }
  & { updateUpgrade: (
    { __typename?: 'UpgradeEntity' }
    & Pick<UpgradeEntity, 'id'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  user: CreateUserDto;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'googleId'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )> }
);

export type GetUserListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserListQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'disabled'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  user: UpdateUserDto;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);


export const MeQueryDocument = gql`
    query meQuery {
  me {
    id
    name
    email
    picture
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useMeQueryQuery__
 *
 * To run a query within a React component, call `useMeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQueryQuery(baseOptions?: Apollo.QueryHookOptions<MeQueryQuery, MeQueryQueryVariables>) {
        return Apollo.useQuery<MeQueryQuery, MeQueryQueryVariables>(MeQueryDocument, baseOptions);
      }
export function useMeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQueryQuery, MeQueryQueryVariables>) {
          return Apollo.useLazyQuery<MeQueryQuery, MeQueryQueryVariables>(MeQueryDocument, baseOptions);
        }
export type MeQueryQueryHookResult = ReturnType<typeof useMeQueryQuery>;
export type MeQueryLazyQueryHookResult = ReturnType<typeof useMeQueryLazyQuery>;
export type MeQueryQueryResult = Apollo.QueryResult<MeQueryQuery, MeQueryQueryVariables>;
export const NoopDocument = gql`
    mutation noop {
  noop
}
    `;
export type NoopMutationFn = Apollo.MutationFunction<NoopMutation, NoopMutationVariables>;

/**
 * __useNoopMutation__
 *
 * To run a mutation, you first call `useNoopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noopMutation, { data, loading, error }] = useNoopMutation({
 *   variables: {
 *   },
 * });
 */
export function useNoopMutation(baseOptions?: Apollo.MutationHookOptions<NoopMutation, NoopMutationVariables>) {
        return Apollo.useMutation<NoopMutation, NoopMutationVariables>(NoopDocument, baseOptions);
      }
export type NoopMutationHookResult = ReturnType<typeof useNoopMutation>;
export type NoopMutationResult = Apollo.MutationResult<NoopMutation>;
export type NoopMutationOptions = Apollo.BaseMutationOptions<NoopMutation, NoopMutationVariables>;
export const RemoveFileDocument = gql`
    mutation removeFile($id: ID!) {
  removeFile(id: $id)
}
    `;
export type RemoveFileMutationFn = Apollo.MutationFunction<RemoveFileMutation, RemoveFileMutationVariables>;

/**
 * __useRemoveFileMutation__
 *
 * To run a mutation, you first call `useRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileMutation, { data, loading, error }] = useRemoveFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFileMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFileMutation, RemoveFileMutationVariables>) {
        return Apollo.useMutation<RemoveFileMutation, RemoveFileMutationVariables>(RemoveFileDocument, baseOptions);
      }
export type RemoveFileMutationHookResult = ReturnType<typeof useRemoveFileMutation>;
export type RemoveFileMutationResult = Apollo.MutationResult<RemoveFileMutation>;
export type RemoveFileMutationOptions = Apollo.BaseMutationOptions<RemoveFileMutation, RemoveFileMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  me {
    id
    email
    name
    picture
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetNotificationListDocument = gql`
    query getNotificationList($ordersFiltering: [FilteringInputType!]!, $invoicesFiltering: [FilteringInputType!]!) {
  orders(
    pagination: {pageIndex: 0, pageSize: 100}
    filtering: $ordersFiltering
    ordering: {}
  ) {
    total
  }
  invoices(
    pagination: {pageIndex: 0, pageSize: 100}
    filtering: $invoicesFiltering
    ordering: {}
  ) {
    total
  }
}
    `;

/**
 * __useGetNotificationListQuery__
 *
 * To run a query within a React component, call `useGetNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationListQuery({
 *   variables: {
 *      ordersFiltering: // value for 'ordersFiltering'
 *      invoicesFiltering: // value for 'invoicesFiltering'
 *   },
 * });
 */
export function useGetNotificationListQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationListQuery, GetNotificationListQueryVariables>) {
        return Apollo.useQuery<GetNotificationListQuery, GetNotificationListQueryVariables>(GetNotificationListDocument, baseOptions);
      }
export function useGetNotificationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationListQuery, GetNotificationListQueryVariables>) {
          return Apollo.useLazyQuery<GetNotificationListQuery, GetNotificationListQueryVariables>(GetNotificationListDocument, baseOptions);
        }
export type GetNotificationListQueryHookResult = ReturnType<typeof useGetNotificationListQuery>;
export type GetNotificationListLazyQueryHookResult = ReturnType<typeof useGetNotificationListLazyQuery>;
export type GetNotificationListQueryResult = Apollo.QueryResult<GetNotificationListQuery, GetNotificationListQueryVariables>;
export const GetPurchaseOrderNotificationDocument = gql`
    query getPurchaseOrderNotification($purchaseOrdersFiltering: [FilteringInputType!]!) {
  purchaseOrders(
    pagination: {pageIndex: 0, pageSize: 100}
    filtering: $purchaseOrdersFiltering
    ordering: {}
  ) {
    total
  }
}
    `;

/**
 * __useGetPurchaseOrderNotificationQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderNotificationQuery({
 *   variables: {
 *      purchaseOrdersFiltering: // value for 'purchaseOrdersFiltering'
 *   },
 * });
 */
export function useGetPurchaseOrderNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetPurchaseOrderNotificationQuery, GetPurchaseOrderNotificationQueryVariables>) {
        return Apollo.useQuery<GetPurchaseOrderNotificationQuery, GetPurchaseOrderNotificationQueryVariables>(GetPurchaseOrderNotificationDocument, baseOptions);
      }
export function useGetPurchaseOrderNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseOrderNotificationQuery, GetPurchaseOrderNotificationQueryVariables>) {
          return Apollo.useLazyQuery<GetPurchaseOrderNotificationQuery, GetPurchaseOrderNotificationQueryVariables>(GetPurchaseOrderNotificationDocument, baseOptions);
        }
export type GetPurchaseOrderNotificationQueryHookResult = ReturnType<typeof useGetPurchaseOrderNotificationQuery>;
export type GetPurchaseOrderNotificationLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderNotificationLazyQuery>;
export type GetPurchaseOrderNotificationQueryResult = Apollo.QueryResult<GetPurchaseOrderNotificationQuery, GetPurchaseOrderNotificationQueryVariables>;
export const PushMessageDocument = gql`
    mutation pushMessage($message: String!) {
  pushMessage(message: $message)
}
    `;
export type PushMessageMutationFn = Apollo.MutationFunction<PushMessageMutation, PushMessageMutationVariables>;

/**
 * __usePushMessageMutation__
 *
 * To run a mutation, you first call `usePushMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushMessageMutation, { data, loading, error }] = usePushMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function usePushMessageMutation(baseOptions?: Apollo.MutationHookOptions<PushMessageMutation, PushMessageMutationVariables>) {
        return Apollo.useMutation<PushMessageMutation, PushMessageMutationVariables>(PushMessageDocument, baseOptions);
      }
export type PushMessageMutationHookResult = ReturnType<typeof usePushMessageMutation>;
export type PushMessageMutationResult = Apollo.MutationResult<PushMessageMutation>;
export type PushMessageMutationOptions = Apollo.BaseMutationOptions<PushMessageMutation, PushMessageMutationVariables>;
export const CreateAdvertiserDocument = gql`
    mutation createAdvertiser($advertiser: AdvertiserInputType!) {
  createAdvertiser(advertiser: $advertiser) {
    id
    name
  }
}
    `;
export type CreateAdvertiserMutationFn = Apollo.MutationFunction<CreateAdvertiserMutation, CreateAdvertiserMutationVariables>;

/**
 * __useCreateAdvertiserMutation__
 *
 * To run a mutation, you first call `useCreateAdvertiserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvertiserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvertiserMutation, { data, loading, error }] = useCreateAdvertiserMutation({
 *   variables: {
 *      advertiser: // value for 'advertiser'
 *   },
 * });
 */
export function useCreateAdvertiserMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdvertiserMutation, CreateAdvertiserMutationVariables>) {
        return Apollo.useMutation<CreateAdvertiserMutation, CreateAdvertiserMutationVariables>(CreateAdvertiserDocument, baseOptions);
      }
export type CreateAdvertiserMutationHookResult = ReturnType<typeof useCreateAdvertiserMutation>;
export type CreateAdvertiserMutationResult = Apollo.MutationResult<CreateAdvertiserMutation>;
export type CreateAdvertiserMutationOptions = Apollo.BaseMutationOptions<CreateAdvertiserMutation, CreateAdvertiserMutationVariables>;
export const RemoveAdvertiserDocument = gql`
    mutation removeAdvertiser($id: ID!) {
  removeAdvertiser(id: $id)
}
    `;
export type RemoveAdvertiserMutationFn = Apollo.MutationFunction<RemoveAdvertiserMutation, RemoveAdvertiserMutationVariables>;

/**
 * __useRemoveAdvertiserMutation__
 *
 * To run a mutation, you first call `useRemoveAdvertiserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdvertiserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdvertiserMutation, { data, loading, error }] = useRemoveAdvertiserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAdvertiserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdvertiserMutation, RemoveAdvertiserMutationVariables>) {
        return Apollo.useMutation<RemoveAdvertiserMutation, RemoveAdvertiserMutationVariables>(RemoveAdvertiserDocument, baseOptions);
      }
export type RemoveAdvertiserMutationHookResult = ReturnType<typeof useRemoveAdvertiserMutation>;
export type RemoveAdvertiserMutationResult = Apollo.MutationResult<RemoveAdvertiserMutation>;
export type RemoveAdvertiserMutationOptions = Apollo.BaseMutationOptions<RemoveAdvertiserMutation, RemoveAdvertiserMutationVariables>;
export const UpdateAdvertiserDocument = gql`
    mutation updateAdvertiser($advertiser: AdvertiserInputType!, $id: ID!) {
  updateAdvertiser(id: $id, advertiser: $advertiser) {
    id
    name
  }
}
    `;
export type UpdateAdvertiserMutationFn = Apollo.MutationFunction<UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables>;

/**
 * __useUpdateAdvertiserMutation__
 *
 * To run a mutation, you first call `useUpdateAdvertiserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvertiserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvertiserMutation, { data, loading, error }] = useUpdateAdvertiserMutation({
 *   variables: {
 *      advertiser: // value for 'advertiser'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAdvertiserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables>) {
        return Apollo.useMutation<UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables>(UpdateAdvertiserDocument, baseOptions);
      }
export type UpdateAdvertiserMutationHookResult = ReturnType<typeof useUpdateAdvertiserMutation>;
export type UpdateAdvertiserMutationResult = Apollo.MutationResult<UpdateAdvertiserMutation>;
export type UpdateAdvertiserMutationOptions = Apollo.BaseMutationOptions<UpdateAdvertiserMutation, UpdateAdvertiserMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($contact: ContactInputType!) {
  createContact(contact: $contact) {
    id
    name
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const RemoveContactDocument = gql`
    mutation removeContact($id: ID!) {
  removeContact(id: $id)
}
    `;
export type RemoveContactMutationFn = Apollo.MutationFunction<RemoveContactMutation, RemoveContactMutationVariables>;

/**
 * __useRemoveContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactMutation, { data, loading, error }] = useRemoveContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContactMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactMutation, RemoveContactMutationVariables>) {
        return Apollo.useMutation<RemoveContactMutation, RemoveContactMutationVariables>(RemoveContactDocument, baseOptions);
      }
export type RemoveContactMutationHookResult = ReturnType<typeof useRemoveContactMutation>;
export type RemoveContactMutationResult = Apollo.MutationResult<RemoveContactMutation>;
export type RemoveContactMutationOptions = Apollo.BaseMutationOptions<RemoveContactMutation, RemoveContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($contact: ContactInputType!, $id: ID!) {
  updateContact(id: $id, contact: $contact) {
    id
    name
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($customer: CustomerInputType!) {
  createCustomer(customer: $customer) {
    id
    name
    phone
    address
    billingAddress
    taxId
    industry {
      id
      name
    }
    invoiceMethod
    bankCode
    bankAccountNumber
    bankAccountName
    passbookImageUrl
    isAgent
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($id: ID!) {
  customer(id: $id) {
    address
    bankAccountName
    bankAccountNumber
    bankCode
    billingAddress
    taxId
    contacts {
      id
      name
      phone
      email
    }
    createdAt
    id
    industry {
      id
      name
    }
    invoiceMethod
    isAgent
    name
    orders {
      id
      number
    }
    passbookImageUrl
    phone
    updatedAt
    fax
    assignee {
      id
      name
    }
    defaultDiscountPercentage
    type
    defaultPaymentDays
    advertisers {
      id
      name
      industry {
        id
        name
      }
      industryTag
      productTag
      fieldTag
    }
    disabled
    bankCode
    bankAccountNumber
    invoices {
      id
      status
      invoiceNumber
      postDate
    }
    virtualAccountNumber
    contractStartDate
    contractEndDate
    contractFiles {
      id
      name
      mimetype
      createdAt
      uri
    }
    creditClass
    registerDate
    capital
    answer {
      operationType
      years
      capital
      lastYearTransaction
      paidCredit
      negativeRecord
      bouncedRecord
    }
    answerEditable
    industryTag
    productTag
    fieldTag
    score
    rebates {
      id
      startDate
      endDate
      type
      products {
        id
        name
        productOrigin
      }
      intervals {
        id
        lowerBound
        upperBound
        rate
      }
    }
    invoiceExpired
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomerBriefListDocument = gql`
    query getCustomerBriefList {
  result: customers(
    pagination: {pageIndex: 0, pageSize: 3000}
    filtering: {comparator: EQUALS, key: "disabled", value: "false"}
  ) {
    total
    list {
      id
      name
      createdAt
      updatedAt
      taxId
      phone
      industry {
        id
        name
      }
      isAgent
      address
      billingAddress
      bankCode
      bankAccountNumber
      invoiceMethod
      defaultPaymentDays
      type
      advertisers {
        id
        name
      }
      contacts {
        id
        email
        name
      }
    }
  }
}
    `;

/**
 * __useGetCustomerBriefListQuery__
 *
 * To run a query within a React component, call `useGetCustomerBriefListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerBriefListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerBriefListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerBriefListQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerBriefListQuery, GetCustomerBriefListQueryVariables>) {
        return Apollo.useQuery<GetCustomerBriefListQuery, GetCustomerBriefListQueryVariables>(GetCustomerBriefListDocument, baseOptions);
      }
export function useGetCustomerBriefListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerBriefListQuery, GetCustomerBriefListQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerBriefListQuery, GetCustomerBriefListQueryVariables>(GetCustomerBriefListDocument, baseOptions);
        }
export type GetCustomerBriefListQueryHookResult = ReturnType<typeof useGetCustomerBriefListQuery>;
export type GetCustomerBriefListLazyQueryHookResult = ReturnType<typeof useGetCustomerBriefListLazyQuery>;
export type GetCustomerBriefListQueryResult = Apollo.QueryResult<GetCustomerBriefListQuery, GetCustomerBriefListQueryVariables>;
export const GetCustomerListDocument = gql`
    query getCustomerList($pagination: PaginationInputType, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType) {
  result: customers(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    total
    list {
      id
      name
      createdAt
      updatedAt
      taxId
      phone
      industry {
        id
        name
      }
      isAgent
      address
      billingAddress
      bankCode
      bankAccountNumber
      contractEndDate
      industryTag
      productTag
      fieldTag
      score
      disabled
    }
  }
}
    `;

/**
 * __useGetCustomerListQuery__
 *
 * To run a query within a React component, call `useGetCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetCustomerListQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
        return Apollo.useQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
      }
export function useGetCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
        }
export type GetCustomerListQueryHookResult = ReturnType<typeof useGetCustomerListQuery>;
export type GetCustomerListLazyQueryHookResult = ReturnType<typeof useGetCustomerListLazyQuery>;
export type GetCustomerListQueryResult = Apollo.QueryResult<GetCustomerListQuery, GetCustomerListQueryVariables>;
export const GetIndustryListDocument = gql`
    query getIndustryList {
  industries {
    id
    name
  }
}
    `;

/**
 * __useGetIndustryListQuery__
 *
 * To run a query within a React component, call `useGetIndustryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndustryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndustryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIndustryListQuery(baseOptions?: Apollo.QueryHookOptions<GetIndustryListQuery, GetIndustryListQueryVariables>) {
        return Apollo.useQuery<GetIndustryListQuery, GetIndustryListQueryVariables>(GetIndustryListDocument, baseOptions);
      }
export function useGetIndustryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndustryListQuery, GetIndustryListQueryVariables>) {
          return Apollo.useLazyQuery<GetIndustryListQuery, GetIndustryListQueryVariables>(GetIndustryListDocument, baseOptions);
        }
export type GetIndustryListQueryHookResult = ReturnType<typeof useGetIndustryListQuery>;
export type GetIndustryListLazyQueryHookResult = ReturnType<typeof useGetIndustryListLazyQuery>;
export type GetIndustryListQueryResult = Apollo.QueryResult<GetIndustryListQuery, GetIndustryListQueryVariables>;
export const RemoveCustomerDocument = gql`
    mutation removeCustomer($id: ID!) {
  removeCustomer(id: $id)
}
    `;
export type RemoveCustomerMutationFn = Apollo.MutationFunction<RemoveCustomerMutation, RemoveCustomerMutationVariables>;

/**
 * __useRemoveCustomerMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerMutation, { data, loading, error }] = useRemoveCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomerMutation, RemoveCustomerMutationVariables>) {
        return Apollo.useMutation<RemoveCustomerMutation, RemoveCustomerMutationVariables>(RemoveCustomerDocument, baseOptions);
      }
export type RemoveCustomerMutationHookResult = ReturnType<typeof useRemoveCustomerMutation>;
export type RemoveCustomerMutationResult = Apollo.MutationResult<RemoveCustomerMutation>;
export type RemoveCustomerMutationOptions = Apollo.BaseMutationOptions<RemoveCustomerMutation, RemoveCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($customer: CustomerInputType!, $id: ID!) {
  updateCustomer(id: $id, customer: $customer) {
    id
    name
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const ActiveDepositsDocument = gql`
    query activeDeposits($pagination: PaginationInputType!, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType!) {
  result: deposits(
    filtering: $filtering
    pagination: $pagination
    ordering: $ordering
  ) {
    list {
      createdAt
      amount
      status
      id
      orderItem {
        id
        totalAmount
      }
    }
  }
}
    `;

/**
 * __useActiveDepositsQuery__
 *
 * To run a query within a React component, call `useActiveDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveDepositsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useActiveDepositsQuery(baseOptions: Apollo.QueryHookOptions<ActiveDepositsQuery, ActiveDepositsQueryVariables>) {
        return Apollo.useQuery<ActiveDepositsQuery, ActiveDepositsQueryVariables>(ActiveDepositsDocument, baseOptions);
      }
export function useActiveDepositsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveDepositsQuery, ActiveDepositsQueryVariables>) {
          return Apollo.useLazyQuery<ActiveDepositsQuery, ActiveDepositsQueryVariables>(ActiveDepositsDocument, baseOptions);
        }
export type ActiveDepositsQueryHookResult = ReturnType<typeof useActiveDepositsQuery>;
export type ActiveDepositsLazyQueryHookResult = ReturnType<typeof useActiveDepositsLazyQuery>;
export type ActiveDepositsQueryResult = Apollo.QueryResult<ActiveDepositsQuery, ActiveDepositsQueryVariables>;
export const AddChangeRecordDocument = gql`
    mutation addChangeRecord($createDepositChangeDto: CreateDepositChangeDto!) {
  addChangeRecord(createDepositChangeDto: $createDepositChangeDto) {
    id
  }
}
    `;
export type AddChangeRecordMutationFn = Apollo.MutationFunction<AddChangeRecordMutation, AddChangeRecordMutationVariables>;

/**
 * __useAddChangeRecordMutation__
 *
 * To run a mutation, you first call `useAddChangeRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChangeRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChangeRecordMutation, { data, loading, error }] = useAddChangeRecordMutation({
 *   variables: {
 *      createDepositChangeDto: // value for 'createDepositChangeDto'
 *   },
 * });
 */
export function useAddChangeRecordMutation(baseOptions?: Apollo.MutationHookOptions<AddChangeRecordMutation, AddChangeRecordMutationVariables>) {
        return Apollo.useMutation<AddChangeRecordMutation, AddChangeRecordMutationVariables>(AddChangeRecordDocument, baseOptions);
      }
export type AddChangeRecordMutationHookResult = ReturnType<typeof useAddChangeRecordMutation>;
export type AddChangeRecordMutationResult = Apollo.MutationResult<AddChangeRecordMutation>;
export type AddChangeRecordMutationOptions = Apollo.BaseMutationOptions<AddChangeRecordMutation, AddChangeRecordMutationVariables>;
export const CreateDepositDocument = gql`
    mutation createDeposit($deposit: CreateDepositDto!) {
  createDeposit(deposit: $deposit) {
    id
    amount
  }
}
    `;
export type CreateDepositMutationFn = Apollo.MutationFunction<CreateDepositMutation, CreateDepositMutationVariables>;

/**
 * __useCreateDepositMutation__
 *
 * To run a mutation, you first call `useCreateDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepositMutation, { data, loading, error }] = useCreateDepositMutation({
 *   variables: {
 *      deposit: // value for 'deposit'
 *   },
 * });
 */
export function useCreateDepositMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepositMutation, CreateDepositMutationVariables>) {
        return Apollo.useMutation<CreateDepositMutation, CreateDepositMutationVariables>(CreateDepositDocument, baseOptions);
      }
export type CreateDepositMutationHookResult = ReturnType<typeof useCreateDepositMutation>;
export type CreateDepositMutationResult = Apollo.MutationResult<CreateDepositMutation>;
export type CreateDepositMutationOptions = Apollo.BaseMutationOptions<CreateDepositMutation, CreateDepositMutationVariables>;
export const GetDepositListDocument = gql`
    query getDepositList($pagination: PaginationInputType!, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType!) {
  result: deposits(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    list {
      id
      amount
      status
      createdAt
      updatedAt
      orderItem {
        id
        order {
          id
          number
          customer {
            id
            name
          }
          assignedSalesUser {
            id
            name
          }
        }
        assignee {
          id
          name
        }
      }
      accountId
      startDate
      endDate
      description
      supplier {
        id
        name
      }
      note
      changes {
        id
        amount
        createdAt
        verifiedAt
        verified
        description
        startDate
        endDate
      }
    }
    total
  }
}
    `;

/**
 * __useGetDepositListQuery__
 *
 * To run a query within a React component, call `useGetDepositListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetDepositListQuery(baseOptions: Apollo.QueryHookOptions<GetDepositListQuery, GetDepositListQueryVariables>) {
        return Apollo.useQuery<GetDepositListQuery, GetDepositListQueryVariables>(GetDepositListDocument, baseOptions);
      }
export function useGetDepositListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepositListQuery, GetDepositListQueryVariables>) {
          return Apollo.useLazyQuery<GetDepositListQuery, GetDepositListQueryVariables>(GetDepositListDocument, baseOptions);
        }
export type GetDepositListQueryHookResult = ReturnType<typeof useGetDepositListQuery>;
export type GetDepositListLazyQueryHookResult = ReturnType<typeof useGetDepositListLazyQuery>;
export type GetDepositListQueryResult = Apollo.QueryResult<GetDepositListQuery, GetDepositListQueryVariables>;
export const RemoveAllDepositDocument = gql`
    mutation removeAllDeposit($id: ID!) {
  removeAllDeposit(id: $id)
}
    `;
export type RemoveAllDepositMutationFn = Apollo.MutationFunction<RemoveAllDepositMutation, RemoveAllDepositMutationVariables>;

/**
 * __useRemoveAllDepositMutation__
 *
 * To run a mutation, you first call `useRemoveAllDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllDepositMutation, { data, loading, error }] = useRemoveAllDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAllDepositMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAllDepositMutation, RemoveAllDepositMutationVariables>) {
        return Apollo.useMutation<RemoveAllDepositMutation, RemoveAllDepositMutationVariables>(RemoveAllDepositDocument, baseOptions);
      }
export type RemoveAllDepositMutationHookResult = ReturnType<typeof useRemoveAllDepositMutation>;
export type RemoveAllDepositMutationResult = Apollo.MutationResult<RemoveAllDepositMutation>;
export type RemoveAllDepositMutationOptions = Apollo.BaseMutationOptions<RemoveAllDepositMutation, RemoveAllDepositMutationVariables>;
export const RemoveDepositDocument = gql`
    mutation removeDeposit($id: ID!) {
  removeDeposit(id: $id)
}
    `;
export type RemoveDepositMutationFn = Apollo.MutationFunction<RemoveDepositMutation, RemoveDepositMutationVariables>;

/**
 * __useRemoveDepositMutation__
 *
 * To run a mutation, you first call `useRemoveDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDepositMutation, { data, loading, error }] = useRemoveDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDepositMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDepositMutation, RemoveDepositMutationVariables>) {
        return Apollo.useMutation<RemoveDepositMutation, RemoveDepositMutationVariables>(RemoveDepositDocument, baseOptions);
      }
export type RemoveDepositMutationHookResult = ReturnType<typeof useRemoveDepositMutation>;
export type RemoveDepositMutationResult = Apollo.MutationResult<RemoveDepositMutation>;
export type RemoveDepositMutationOptions = Apollo.BaseMutationOptions<RemoveDepositMutation, RemoveDepositMutationVariables>;
export const ReviewDepositDocument = gql`
    mutation reviewDeposit($id: ID!, $approved: Boolean!) {
  reviewDeposit(id: $id, approved: $approved) {
    id
  }
}
    `;
export type ReviewDepositMutationFn = Apollo.MutationFunction<ReviewDepositMutation, ReviewDepositMutationVariables>;

/**
 * __useReviewDepositMutation__
 *
 * To run a mutation, you first call `useReviewDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewDepositMutation, { data, loading, error }] = useReviewDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useReviewDepositMutation(baseOptions?: Apollo.MutationHookOptions<ReviewDepositMutation, ReviewDepositMutationVariables>) {
        return Apollo.useMutation<ReviewDepositMutation, ReviewDepositMutationVariables>(ReviewDepositDocument, baseOptions);
      }
export type ReviewDepositMutationHookResult = ReturnType<typeof useReviewDepositMutation>;
export type ReviewDepositMutationResult = Apollo.MutationResult<ReviewDepositMutation>;
export type ReviewDepositMutationOptions = Apollo.BaseMutationOptions<ReviewDepositMutation, ReviewDepositMutationVariables>;
export const UpdateDepositDocument = gql`
    mutation updateDeposit($deposit: UpdateDepositDto!, $id: ID!) {
  updateDeposit(deposit: $deposit, id: $id) {
    id
    amount
  }
}
    `;
export type UpdateDepositMutationFn = Apollo.MutationFunction<UpdateDepositMutation, UpdateDepositMutationVariables>;

/**
 * __useUpdateDepositMutation__
 *
 * To run a mutation, you first call `useUpdateDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepositMutation, { data, loading, error }] = useUpdateDepositMutation({
 *   variables: {
 *      deposit: // value for 'deposit'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDepositMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepositMutation, UpdateDepositMutationVariables>) {
        return Apollo.useMutation<UpdateDepositMutation, UpdateDepositMutationVariables>(UpdateDepositDocument, baseOptions);
      }
export type UpdateDepositMutationHookResult = ReturnType<typeof useUpdateDepositMutation>;
export type UpdateDepositMutationResult = Apollo.MutationResult<UpdateDepositMutation>;
export type UpdateDepositMutationOptions = Apollo.BaseMutationOptions<UpdateDepositMutation, UpdateDepositMutationVariables>;
export const GetMarkupSettingDocument = gql`
    query GetMarkupSetting($accountId: String!, $adType: String!) {
  getMarkupSettingByAccountAndType(accountId: $accountId, adType: $adType) {
    id
    accountId
    adType
    defaultMarkup
    markupMap
  }
}
    `;

/**
 * __useGetMarkupSettingQuery__
 *
 * To run a query within a React component, call `useGetMarkupSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkupSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkupSettingQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      adType: // value for 'adType'
 *   },
 * });
 */
export function useGetMarkupSettingQuery(baseOptions: Apollo.QueryHookOptions<GetMarkupSettingQuery, GetMarkupSettingQueryVariables>) {
        return Apollo.useQuery<GetMarkupSettingQuery, GetMarkupSettingQueryVariables>(GetMarkupSettingDocument, baseOptions);
      }
export function useGetMarkupSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkupSettingQuery, GetMarkupSettingQueryVariables>) {
          return Apollo.useLazyQuery<GetMarkupSettingQuery, GetMarkupSettingQueryVariables>(GetMarkupSettingDocument, baseOptions);
        }
export type GetMarkupSettingQueryHookResult = ReturnType<typeof useGetMarkupSettingQuery>;
export type GetMarkupSettingLazyQueryHookResult = ReturnType<typeof useGetMarkupSettingLazyQuery>;
export type GetMarkupSettingQueryResult = Apollo.QueryResult<GetMarkupSettingQuery, GetMarkupSettingQueryVariables>;
export const UpsertMarkupSettingDocument = gql`
    mutation UpsertMarkupSetting($markupSettingInput: MarkupSettingInput!) {
  upsertMarkupSetting(markupSettingInput: $markupSettingInput) {
    id
    accountId
    adType
    defaultMarkup
    markupMap
  }
}
    `;
export type UpsertMarkupSettingMutationFn = Apollo.MutationFunction<UpsertMarkupSettingMutation, UpsertMarkupSettingMutationVariables>;

/**
 * __useUpsertMarkupSettingMutation__
 *
 * To run a mutation, you first call `useUpsertMarkupSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMarkupSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMarkupSettingMutation, { data, loading, error }] = useUpsertMarkupSettingMutation({
 *   variables: {
 *      markupSettingInput: // value for 'markupSettingInput'
 *   },
 * });
 */
export function useUpsertMarkupSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMarkupSettingMutation, UpsertMarkupSettingMutationVariables>) {
        return Apollo.useMutation<UpsertMarkupSettingMutation, UpsertMarkupSettingMutationVariables>(UpsertMarkupSettingDocument, baseOptions);
      }
export type UpsertMarkupSettingMutationHookResult = ReturnType<typeof useUpsertMarkupSettingMutation>;
export type UpsertMarkupSettingMutationResult = Apollo.MutationResult<UpsertMarkupSettingMutation>;
export type UpsertMarkupSettingMutationOptions = Apollo.BaseMutationOptions<UpsertMarkupSettingMutation, UpsertMarkupSettingMutationVariables>;
export const CreateGoogleAdsRecordDocument = gql`
    mutation createGoogleAdsRecord($googleAdsRecordInput: GoogleAdsRecordInput!) {
  createGoogleAdsRecord(googleAdsRecord: $googleAdsRecordInput) {
    id
  }
}
    `;
export type CreateGoogleAdsRecordMutationFn = Apollo.MutationFunction<CreateGoogleAdsRecordMutation, CreateGoogleAdsRecordMutationVariables>;

/**
 * __useCreateGoogleAdsRecordMutation__
 *
 * To run a mutation, you first call `useCreateGoogleAdsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoogleAdsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoogleAdsRecordMutation, { data, loading, error }] = useCreateGoogleAdsRecordMutation({
 *   variables: {
 *      googleAdsRecordInput: // value for 'googleAdsRecordInput'
 *   },
 * });
 */
export function useCreateGoogleAdsRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateGoogleAdsRecordMutation, CreateGoogleAdsRecordMutationVariables>) {
        return Apollo.useMutation<CreateGoogleAdsRecordMutation, CreateGoogleAdsRecordMutationVariables>(CreateGoogleAdsRecordDocument, baseOptions);
      }
export type CreateGoogleAdsRecordMutationHookResult = ReturnType<typeof useCreateGoogleAdsRecordMutation>;
export type CreateGoogleAdsRecordMutationResult = Apollo.MutationResult<CreateGoogleAdsRecordMutation>;
export type CreateGoogleAdsRecordMutationOptions = Apollo.BaseMutationOptions<CreateGoogleAdsRecordMutation, CreateGoogleAdsRecordMutationVariables>;
export const CreateMetaAdsRecordDocument = gql`
    mutation createMetaAdsRecord($metaAdsRecordInput: MetaAdsRecordInput!) {
  createMetaAdsRecord(metaAdsRecord: $metaAdsRecordInput) {
    id
  }
}
    `;
export type CreateMetaAdsRecordMutationFn = Apollo.MutationFunction<CreateMetaAdsRecordMutation, CreateMetaAdsRecordMutationVariables>;

/**
 * __useCreateMetaAdsRecordMutation__
 *
 * To run a mutation, you first call `useCreateMetaAdsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetaAdsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetaAdsRecordMutation, { data, loading, error }] = useCreateMetaAdsRecordMutation({
 *   variables: {
 *      metaAdsRecordInput: // value for 'metaAdsRecordInput'
 *   },
 * });
 */
export function useCreateMetaAdsRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateMetaAdsRecordMutation, CreateMetaAdsRecordMutationVariables>) {
        return Apollo.useMutation<CreateMetaAdsRecordMutation, CreateMetaAdsRecordMutationVariables>(CreateMetaAdsRecordDocument, baseOptions);
      }
export type CreateMetaAdsRecordMutationHookResult = ReturnType<typeof useCreateMetaAdsRecordMutation>;
export type CreateMetaAdsRecordMutationResult = Apollo.MutationResult<CreateMetaAdsRecordMutation>;
export type CreateMetaAdsRecordMutationOptions = Apollo.BaseMutationOptions<CreateMetaAdsRecordMutation, CreateMetaAdsRecordMutationVariables>;
export const GetGoogleAdsRecordListDocument = gql`
    query getGoogleAdsRecordList {
  googleAdsRecords {
    id
    name
    startDate
    endDate
    createdAt
    assignee {
      id
      name
    }
    status
  }
}
    `;

/**
 * __useGetGoogleAdsRecordListQuery__
 *
 * To run a query within a React component, call `useGetGoogleAdsRecordListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAdsRecordListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAdsRecordListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleAdsRecordListQuery(baseOptions?: Apollo.QueryHookOptions<GetGoogleAdsRecordListQuery, GetGoogleAdsRecordListQueryVariables>) {
        return Apollo.useQuery<GetGoogleAdsRecordListQuery, GetGoogleAdsRecordListQueryVariables>(GetGoogleAdsRecordListDocument, baseOptions);
      }
export function useGetGoogleAdsRecordListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAdsRecordListQuery, GetGoogleAdsRecordListQueryVariables>) {
          return Apollo.useLazyQuery<GetGoogleAdsRecordListQuery, GetGoogleAdsRecordListQueryVariables>(GetGoogleAdsRecordListDocument, baseOptions);
        }
export type GetGoogleAdsRecordListQueryHookResult = ReturnType<typeof useGetGoogleAdsRecordListQuery>;
export type GetGoogleAdsRecordListLazyQueryHookResult = ReturnType<typeof useGetGoogleAdsRecordListLazyQuery>;
export type GetGoogleAdsRecordListQueryResult = Apollo.QueryResult<GetGoogleAdsRecordListQuery, GetGoogleAdsRecordListQueryVariables>;
export const GetMetaAdsRecordListDocument = gql`
    query getMetaAdsRecordList {
  metaAdsRecords {
    id
    name
    startDate
    endDate
    createdAt
    assignee {
      id
      name
    }
    status
  }
}
    `;

/**
 * __useGetMetaAdsRecordListQuery__
 *
 * To run a query within a React component, call `useGetMetaAdsRecordListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaAdsRecordListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaAdsRecordListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetaAdsRecordListQuery(baseOptions?: Apollo.QueryHookOptions<GetMetaAdsRecordListQuery, GetMetaAdsRecordListQueryVariables>) {
        return Apollo.useQuery<GetMetaAdsRecordListQuery, GetMetaAdsRecordListQueryVariables>(GetMetaAdsRecordListDocument, baseOptions);
      }
export function useGetMetaAdsRecordListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetaAdsRecordListQuery, GetMetaAdsRecordListQueryVariables>) {
          return Apollo.useLazyQuery<GetMetaAdsRecordListQuery, GetMetaAdsRecordListQueryVariables>(GetMetaAdsRecordListDocument, baseOptions);
        }
export type GetMetaAdsRecordListQueryHookResult = ReturnType<typeof useGetMetaAdsRecordListQuery>;
export type GetMetaAdsRecordListLazyQueryHookResult = ReturnType<typeof useGetMetaAdsRecordListLazyQuery>;
export type GetMetaAdsRecordListQueryResult = Apollo.QueryResult<GetMetaAdsRecordListQuery, GetMetaAdsRecordListQueryVariables>;
export const RemoveGoogleAdsRecordDocument = gql`
    mutation removeGoogleAdsRecord($id: ID!) {
  removeGoogleAdsRecord(id: $id)
}
    `;
export type RemoveGoogleAdsRecordMutationFn = Apollo.MutationFunction<RemoveGoogleAdsRecordMutation, RemoveGoogleAdsRecordMutationVariables>;

/**
 * __useRemoveGoogleAdsRecordMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleAdsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleAdsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleAdsRecordMutation, { data, loading, error }] = useRemoveGoogleAdsRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGoogleAdsRecordMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGoogleAdsRecordMutation, RemoveGoogleAdsRecordMutationVariables>) {
        return Apollo.useMutation<RemoveGoogleAdsRecordMutation, RemoveGoogleAdsRecordMutationVariables>(RemoveGoogleAdsRecordDocument, baseOptions);
      }
export type RemoveGoogleAdsRecordMutationHookResult = ReturnType<typeof useRemoveGoogleAdsRecordMutation>;
export type RemoveGoogleAdsRecordMutationResult = Apollo.MutationResult<RemoveGoogleAdsRecordMutation>;
export type RemoveGoogleAdsRecordMutationOptions = Apollo.BaseMutationOptions<RemoveGoogleAdsRecordMutation, RemoveGoogleAdsRecordMutationVariables>;
export const RemoveMetaAdsRecordDocument = gql`
    mutation removeMetaAdsRecord($id: ID!) {
  removeMetaAdsRecord(id: $id)
}
    `;
export type RemoveMetaAdsRecordMutationFn = Apollo.MutationFunction<RemoveMetaAdsRecordMutation, RemoveMetaAdsRecordMutationVariables>;

/**
 * __useRemoveMetaAdsRecordMutation__
 *
 * To run a mutation, you first call `useRemoveMetaAdsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMetaAdsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMetaAdsRecordMutation, { data, loading, error }] = useRemoveMetaAdsRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMetaAdsRecordMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMetaAdsRecordMutation, RemoveMetaAdsRecordMutationVariables>) {
        return Apollo.useMutation<RemoveMetaAdsRecordMutation, RemoveMetaAdsRecordMutationVariables>(RemoveMetaAdsRecordDocument, baseOptions);
      }
export type RemoveMetaAdsRecordMutationHookResult = ReturnType<typeof useRemoveMetaAdsRecordMutation>;
export type RemoveMetaAdsRecordMutationResult = Apollo.MutationResult<RemoveMetaAdsRecordMutation>;
export type RemoveMetaAdsRecordMutationOptions = Apollo.BaseMutationOptions<RemoveMetaAdsRecordMutation, RemoveMetaAdsRecordMutationVariables>;
export const CreateGoogleAdsTemplateDocument = gql`
    mutation createGoogleAdsTemplate($googleAdsTemplateInput: GoogleAdsTemplateInput!) {
  createGoogleAdsTemplate(googleAdsTemplate: $googleAdsTemplateInput) {
    id
  }
}
    `;
export type CreateGoogleAdsTemplateMutationFn = Apollo.MutationFunction<CreateGoogleAdsTemplateMutation, CreateGoogleAdsTemplateMutationVariables>;

/**
 * __useCreateGoogleAdsTemplateMutation__
 *
 * To run a mutation, you first call `useCreateGoogleAdsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoogleAdsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoogleAdsTemplateMutation, { data, loading, error }] = useCreateGoogleAdsTemplateMutation({
 *   variables: {
 *      googleAdsTemplateInput: // value for 'googleAdsTemplateInput'
 *   },
 * });
 */
export function useCreateGoogleAdsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateGoogleAdsTemplateMutation, CreateGoogleAdsTemplateMutationVariables>) {
        return Apollo.useMutation<CreateGoogleAdsTemplateMutation, CreateGoogleAdsTemplateMutationVariables>(CreateGoogleAdsTemplateDocument, baseOptions);
      }
export type CreateGoogleAdsTemplateMutationHookResult = ReturnType<typeof useCreateGoogleAdsTemplateMutation>;
export type CreateGoogleAdsTemplateMutationResult = Apollo.MutationResult<CreateGoogleAdsTemplateMutation>;
export type CreateGoogleAdsTemplateMutationOptions = Apollo.BaseMutationOptions<CreateGoogleAdsTemplateMutation, CreateGoogleAdsTemplateMutationVariables>;
export const GetAdsAccountsDocument = gql`
    query GetAdsAccounts {
  adsAccounts {
    name
    id
    isManager
    level
  }
}
    `;

/**
 * __useGetAdsAccountsQuery__
 *
 * To run a query within a React component, call `useGetAdsAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdsAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdsAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdsAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdsAccountsQuery, GetAdsAccountsQueryVariables>) {
        return Apollo.useQuery<GetAdsAccountsQuery, GetAdsAccountsQueryVariables>(GetAdsAccountsDocument, baseOptions);
      }
export function useGetAdsAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdsAccountsQuery, GetAdsAccountsQueryVariables>) {
          return Apollo.useLazyQuery<GetAdsAccountsQuery, GetAdsAccountsQueryVariables>(GetAdsAccountsDocument, baseOptions);
        }
export type GetAdsAccountsQueryHookResult = ReturnType<typeof useGetAdsAccountsQuery>;
export type GetAdsAccountsLazyQueryHookResult = ReturnType<typeof useGetAdsAccountsLazyQuery>;
export type GetAdsAccountsQueryResult = Apollo.QueryResult<GetAdsAccountsQuery, GetAdsAccountsQueryVariables>;
export const CampaignsDocument = gql`
    query campaigns($cid: ID!) {
  campaigns(cid: $cid) {
    id
    name
  }
}
    `;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *      cid: // value for 'cid'
 *   },
 * });
 */
export function useCampaignsQuery(baseOptions: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, baseOptions);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, baseOptions);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;
export const GetGoogleAdsTemplatesDocument = gql`
    query getGoogleAdsTemplates {
  googleAdsTemplates {
    id
    name
    accountCampaigns
    adTypes
    reportNames
    removeUnexposed
    metricsFields
    assignee {
      id
      name
    }
  }
}
    `;

/**
 * __useGetGoogleAdsTemplatesQuery__
 *
 * To run a query within a React component, call `useGetGoogleAdsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAdsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAdsTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleAdsTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetGoogleAdsTemplatesQuery, GetGoogleAdsTemplatesQueryVariables>) {
        return Apollo.useQuery<GetGoogleAdsTemplatesQuery, GetGoogleAdsTemplatesQueryVariables>(GetGoogleAdsTemplatesDocument, baseOptions);
      }
export function useGetGoogleAdsTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAdsTemplatesQuery, GetGoogleAdsTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<GetGoogleAdsTemplatesQuery, GetGoogleAdsTemplatesQueryVariables>(GetGoogleAdsTemplatesDocument, baseOptions);
        }
export type GetGoogleAdsTemplatesQueryHookResult = ReturnType<typeof useGetGoogleAdsTemplatesQuery>;
export type GetGoogleAdsTemplatesLazyQueryHookResult = ReturnType<typeof useGetGoogleAdsTemplatesLazyQuery>;
export type GetGoogleAdsTemplatesQueryResult = Apollo.QueryResult<GetGoogleAdsTemplatesQuery, GetGoogleAdsTemplatesQueryVariables>;
export const RemoveGoogleAdsTemplateDocument = gql`
    mutation removeGoogleAdsTemplate($id: ID!) {
  removeGoogleAdsTemplate(id: $id)
}
    `;
export type RemoveGoogleAdsTemplateMutationFn = Apollo.MutationFunction<RemoveGoogleAdsTemplateMutation, RemoveGoogleAdsTemplateMutationVariables>;

/**
 * __useRemoveGoogleAdsTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleAdsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleAdsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleAdsTemplateMutation, { data, loading, error }] = useRemoveGoogleAdsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGoogleAdsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGoogleAdsTemplateMutation, RemoveGoogleAdsTemplateMutationVariables>) {
        return Apollo.useMutation<RemoveGoogleAdsTemplateMutation, RemoveGoogleAdsTemplateMutationVariables>(RemoveGoogleAdsTemplateDocument, baseOptions);
      }
export type RemoveGoogleAdsTemplateMutationHookResult = ReturnType<typeof useRemoveGoogleAdsTemplateMutation>;
export type RemoveGoogleAdsTemplateMutationResult = Apollo.MutationResult<RemoveGoogleAdsTemplateMutation>;
export type RemoveGoogleAdsTemplateMutationOptions = Apollo.BaseMutationOptions<RemoveGoogleAdsTemplateMutation, RemoveGoogleAdsTemplateMutationVariables>;
export const UpdateGoogleAdsTemplateDocument = gql`
    mutation updateGoogleAdsTemplate($id: ID!, $googleAdsTemplateInput: GoogleAdsTemplateInput!) {
  updateGoogleAdsTemplate(id: $id, googleAdsTemplate: $googleAdsTemplateInput) {
    id
  }
}
    `;
export type UpdateGoogleAdsTemplateMutationFn = Apollo.MutationFunction<UpdateGoogleAdsTemplateMutation, UpdateGoogleAdsTemplateMutationVariables>;

/**
 * __useUpdateGoogleAdsTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateGoogleAdsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoogleAdsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoogleAdsTemplateMutation, { data, loading, error }] = useUpdateGoogleAdsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      googleAdsTemplateInput: // value for 'googleAdsTemplateInput'
 *   },
 * });
 */
export function useUpdateGoogleAdsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoogleAdsTemplateMutation, UpdateGoogleAdsTemplateMutationVariables>) {
        return Apollo.useMutation<UpdateGoogleAdsTemplateMutation, UpdateGoogleAdsTemplateMutationVariables>(UpdateGoogleAdsTemplateDocument, baseOptions);
      }
export type UpdateGoogleAdsTemplateMutationHookResult = ReturnType<typeof useUpdateGoogleAdsTemplateMutation>;
export type UpdateGoogleAdsTemplateMutationResult = Apollo.MutationResult<UpdateGoogleAdsTemplateMutation>;
export type UpdateGoogleAdsTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateGoogleAdsTemplateMutation, UpdateGoogleAdsTemplateMutationVariables>;
export const CreateMetaAdsTemplateDocument = gql`
    mutation createMetaAdsTemplate($metaAdsTemplateInput: MetaAdsTemplateInput!) {
  createMetaAdsTemplate(metaAdsTemplate: $metaAdsTemplateInput) {
    id
  }
}
    `;
export type CreateMetaAdsTemplateMutationFn = Apollo.MutationFunction<CreateMetaAdsTemplateMutation, CreateMetaAdsTemplateMutationVariables>;

/**
 * __useCreateMetaAdsTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMetaAdsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetaAdsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetaAdsTemplateMutation, { data, loading, error }] = useCreateMetaAdsTemplateMutation({
 *   variables: {
 *      metaAdsTemplateInput: // value for 'metaAdsTemplateInput'
 *   },
 * });
 */
export function useCreateMetaAdsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMetaAdsTemplateMutation, CreateMetaAdsTemplateMutationVariables>) {
        return Apollo.useMutation<CreateMetaAdsTemplateMutation, CreateMetaAdsTemplateMutationVariables>(CreateMetaAdsTemplateDocument, baseOptions);
      }
export type CreateMetaAdsTemplateMutationHookResult = ReturnType<typeof useCreateMetaAdsTemplateMutation>;
export type CreateMetaAdsTemplateMutationResult = Apollo.MutationResult<CreateMetaAdsTemplateMutation>;
export type CreateMetaAdsTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMetaAdsTemplateMutation, CreateMetaAdsTemplateMutationVariables>;
export const GetMetaAdsAccountListDocument = gql`
    query getMetaAdsAccountList {
  metaAdsAccounts {
    id
    name
  }
}
    `;

/**
 * __useGetMetaAdsAccountListQuery__
 *
 * To run a query within a React component, call `useGetMetaAdsAccountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaAdsAccountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaAdsAccountListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetaAdsAccountListQuery(baseOptions?: Apollo.QueryHookOptions<GetMetaAdsAccountListQuery, GetMetaAdsAccountListQueryVariables>) {
        return Apollo.useQuery<GetMetaAdsAccountListQuery, GetMetaAdsAccountListQueryVariables>(GetMetaAdsAccountListDocument, baseOptions);
      }
export function useGetMetaAdsAccountListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetaAdsAccountListQuery, GetMetaAdsAccountListQueryVariables>) {
          return Apollo.useLazyQuery<GetMetaAdsAccountListQuery, GetMetaAdsAccountListQueryVariables>(GetMetaAdsAccountListDocument, baseOptions);
        }
export type GetMetaAdsAccountListQueryHookResult = ReturnType<typeof useGetMetaAdsAccountListQuery>;
export type GetMetaAdsAccountListLazyQueryHookResult = ReturnType<typeof useGetMetaAdsAccountListLazyQuery>;
export type GetMetaAdsAccountListQueryResult = Apollo.QueryResult<GetMetaAdsAccountListQuery, GetMetaAdsAccountListQueryVariables>;
export const GetMetaCampaignListDocument = gql`
    query getMetaCampaignList($cid: String!) {
  metaAdsCampaigns(accountId: $cid) {
    id
    name
  }
}
    `;

/**
 * __useGetMetaCampaignListQuery__
 *
 * To run a query within a React component, call `useGetMetaCampaignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaCampaignListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaCampaignListQuery({
 *   variables: {
 *      cid: // value for 'cid'
 *   },
 * });
 */
export function useGetMetaCampaignListQuery(baseOptions: Apollo.QueryHookOptions<GetMetaCampaignListQuery, GetMetaCampaignListQueryVariables>) {
        return Apollo.useQuery<GetMetaCampaignListQuery, GetMetaCampaignListQueryVariables>(GetMetaCampaignListDocument, baseOptions);
      }
export function useGetMetaCampaignListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetaCampaignListQuery, GetMetaCampaignListQueryVariables>) {
          return Apollo.useLazyQuery<GetMetaCampaignListQuery, GetMetaCampaignListQueryVariables>(GetMetaCampaignListDocument, baseOptions);
        }
export type GetMetaCampaignListQueryHookResult = ReturnType<typeof useGetMetaCampaignListQuery>;
export type GetMetaCampaignListLazyQueryHookResult = ReturnType<typeof useGetMetaCampaignListLazyQuery>;
export type GetMetaCampaignListQueryResult = Apollo.QueryResult<GetMetaCampaignListQuery, GetMetaCampaignListQueryVariables>;
export const GetMetaTemplatesDocument = gql`
    query getMetaTemplates {
  metaAdsTemplates {
    id
    name
    accountCampaigns
    reportNames
    metricsFields
    assignee {
      id
      name
    }
    removeUnexposed
  }
}
    `;

/**
 * __useGetMetaTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMetaTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetaTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetMetaTemplatesQuery, GetMetaTemplatesQueryVariables>) {
        return Apollo.useQuery<GetMetaTemplatesQuery, GetMetaTemplatesQueryVariables>(GetMetaTemplatesDocument, baseOptions);
      }
export function useGetMetaTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetaTemplatesQuery, GetMetaTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<GetMetaTemplatesQuery, GetMetaTemplatesQueryVariables>(GetMetaTemplatesDocument, baseOptions);
        }
export type GetMetaTemplatesQueryHookResult = ReturnType<typeof useGetMetaTemplatesQuery>;
export type GetMetaTemplatesLazyQueryHookResult = ReturnType<typeof useGetMetaTemplatesLazyQuery>;
export type GetMetaTemplatesQueryResult = Apollo.QueryResult<GetMetaTemplatesQuery, GetMetaTemplatesQueryVariables>;
export const RemoveMetaAdsTemplateDocument = gql`
    mutation removeMetaAdsTemplate($id: ID!) {
  removeMetaAdsTemplate(id: $id)
}
    `;
export type RemoveMetaAdsTemplateMutationFn = Apollo.MutationFunction<RemoveMetaAdsTemplateMutation, RemoveMetaAdsTemplateMutationVariables>;

/**
 * __useRemoveMetaAdsTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveMetaAdsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMetaAdsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMetaAdsTemplateMutation, { data, loading, error }] = useRemoveMetaAdsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMetaAdsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMetaAdsTemplateMutation, RemoveMetaAdsTemplateMutationVariables>) {
        return Apollo.useMutation<RemoveMetaAdsTemplateMutation, RemoveMetaAdsTemplateMutationVariables>(RemoveMetaAdsTemplateDocument, baseOptions);
      }
export type RemoveMetaAdsTemplateMutationHookResult = ReturnType<typeof useRemoveMetaAdsTemplateMutation>;
export type RemoveMetaAdsTemplateMutationResult = Apollo.MutationResult<RemoveMetaAdsTemplateMutation>;
export type RemoveMetaAdsTemplateMutationOptions = Apollo.BaseMutationOptions<RemoveMetaAdsTemplateMutation, RemoveMetaAdsTemplateMutationVariables>;
export const UpdateMetaAdsTemplateDocument = gql`
    mutation updateMetaAdsTemplate($id: ID!, $metaAdsTemplateInput: MetaAdsTemplateInput!) {
  updateMetaAdsTemplate(id: $id, metaAdsTemplate: $metaAdsTemplateInput) {
    id
  }
}
    `;
export type UpdateMetaAdsTemplateMutationFn = Apollo.MutationFunction<UpdateMetaAdsTemplateMutation, UpdateMetaAdsTemplateMutationVariables>;

/**
 * __useUpdateMetaAdsTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMetaAdsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetaAdsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetaAdsTemplateMutation, { data, loading, error }] = useUpdateMetaAdsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      metaAdsTemplateInput: // value for 'metaAdsTemplateInput'
 *   },
 * });
 */
export function useUpdateMetaAdsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMetaAdsTemplateMutation, UpdateMetaAdsTemplateMutationVariables>) {
        return Apollo.useMutation<UpdateMetaAdsTemplateMutation, UpdateMetaAdsTemplateMutationVariables>(UpdateMetaAdsTemplateDocument, baseOptions);
      }
export type UpdateMetaAdsTemplateMutationHookResult = ReturnType<typeof useUpdateMetaAdsTemplateMutation>;
export type UpdateMetaAdsTemplateMutationResult = Apollo.MutationResult<UpdateMetaAdsTemplateMutation>;
export type UpdateMetaAdsTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMetaAdsTemplateMutation, UpdateMetaAdsTemplateMutationVariables>;
export const CreateInvoicesDocument = gql`
    mutation createInvoices($invoices: [CreateInvoiceDto!]!) {
  createInvoices(invoices: $invoices) {
    id
    createdAt
    status
    postDate
    date
  }
}
    `;
export type CreateInvoicesMutationFn = Apollo.MutationFunction<CreateInvoicesMutation, CreateInvoicesMutationVariables>;

/**
 * __useCreateInvoicesMutation__
 *
 * To run a mutation, you first call `useCreateInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoicesMutation, { data, loading, error }] = useCreateInvoicesMutation({
 *   variables: {
 *      invoices: // value for 'invoices'
 *   },
 * });
 */
export function useCreateInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoicesMutation, CreateInvoicesMutationVariables>) {
        return Apollo.useMutation<CreateInvoicesMutation, CreateInvoicesMutationVariables>(CreateInvoicesDocument, baseOptions);
      }
export type CreateInvoicesMutationHookResult = ReturnType<typeof useCreateInvoicesMutation>;
export type CreateInvoicesMutationResult = Apollo.MutationResult<CreateInvoicesMutation>;
export type CreateInvoicesMutationOptions = Apollo.BaseMutationOptions<CreateInvoicesMutation, CreateInvoicesMutationVariables>;
export const GetInvoiceDocument = gql`
    query getInvoice($id: ID!) {
  invoice(id: $id) {
    id
    postDate
    createdAt
    date
    order {
      id
      name
      number
      customer {
        defaultDiscountPercentage
      }
      assignedSalesUser {
        id
        name
      }
    }
    items {
      id
      amount
      name
      changedAmount
      orderItem {
        id
        totalAmount
      }
    }
    status
    discountPercentage
    currency
    description
    invoiceNumber
    totalAmount
    paidAmount
    note
    creditDate
    expired
  }
}
    `;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const GetInvoiceListDocument = gql`
    query getInvoiceList($pagination: PaginationInputType!, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType!) {
  result: invoices(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    list {
      id
      date
      postDate
      createdAt
      status
      order {
        id
        name
        number
      }
      invoiceNumber
      expired
    }
    total
  }
}
    `;

/**
 * __useGetInvoiceListQuery__
 *
 * To run a query within a React component, call `useGetInvoiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetInvoiceListQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceListQuery, GetInvoiceListQueryVariables>) {
        return Apollo.useQuery<GetInvoiceListQuery, GetInvoiceListQueryVariables>(GetInvoiceListDocument, baseOptions);
      }
export function useGetInvoiceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceListQuery, GetInvoiceListQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceListQuery, GetInvoiceListQueryVariables>(GetInvoiceListDocument, baseOptions);
        }
export type GetInvoiceListQueryHookResult = ReturnType<typeof useGetInvoiceListQuery>;
export type GetInvoiceListLazyQueryHookResult = ReturnType<typeof useGetInvoiceListLazyQuery>;
export type GetInvoiceListQueryResult = Apollo.QueryResult<GetInvoiceListQuery, GetInvoiceListQueryVariables>;
export const GetInvoiceNotificationDocument = gql`
    query getInvoiceNotification($invoicesFiltering: [FilteringInputType!]!) {
  invoices(
    pagination: {pageIndex: 0, pageSize: 1000}
    filtering: $invoicesFiltering
    ordering: {}
  ) {
    total
  }
}
    `;

/**
 * __useGetInvoiceNotificationQuery__
 *
 * To run a query within a React component, call `useGetInvoiceNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceNotificationQuery({
 *   variables: {
 *      invoicesFiltering: // value for 'invoicesFiltering'
 *   },
 * });
 */
export function useGetInvoiceNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceNotificationQuery, GetInvoiceNotificationQueryVariables>) {
        return Apollo.useQuery<GetInvoiceNotificationQuery, GetInvoiceNotificationQueryVariables>(GetInvoiceNotificationDocument, baseOptions);
      }
export function useGetInvoiceNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceNotificationQuery, GetInvoiceNotificationQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceNotificationQuery, GetInvoiceNotificationQueryVariables>(GetInvoiceNotificationDocument, baseOptions);
        }
export type GetInvoiceNotificationQueryHookResult = ReturnType<typeof useGetInvoiceNotificationQuery>;
export type GetInvoiceNotificationLazyQueryHookResult = ReturnType<typeof useGetInvoiceNotificationLazyQuery>;
export type GetInvoiceNotificationQueryResult = Apollo.QueryResult<GetInvoiceNotificationQuery, GetInvoiceNotificationQueryVariables>;
export const RemoveInvoiceDocument = gql`
    mutation removeInvoice($id: ID!) {
  removeInvoice(id: $id)
}
    `;
export type RemoveInvoiceMutationFn = Apollo.MutationFunction<RemoveInvoiceMutation, RemoveInvoiceMutationVariables>;

/**
 * __useRemoveInvoiceMutation__
 *
 * To run a mutation, you first call `useRemoveInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInvoiceMutation, { data, loading, error }] = useRemoveInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInvoiceMutation, RemoveInvoiceMutationVariables>) {
        return Apollo.useMutation<RemoveInvoiceMutation, RemoveInvoiceMutationVariables>(RemoveInvoiceDocument, baseOptions);
      }
export type RemoveInvoiceMutationHookResult = ReturnType<typeof useRemoveInvoiceMutation>;
export type RemoveInvoiceMutationResult = Apollo.MutationResult<RemoveInvoiceMutation>;
export type RemoveInvoiceMutationOptions = Apollo.BaseMutationOptions<RemoveInvoiceMutation, RemoveInvoiceMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation updateInvoice($invoice: UpdateInvoiceDto!, $id: ID!) {
  updateInvoice(invoice: $invoice, id: $id) {
    date
    postDate
    status
  }
}
    `;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, baseOptions);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const CreateLogDocument = gql`
    mutation createLog($log: CreateLogDTO!) {
  createLog(log: $log) {
    id
  }
}
    `;
export type CreateLogMutationFn = Apollo.MutationFunction<CreateLogMutation, CreateLogMutationVariables>;

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *      log: // value for 'log'
 *   },
 * });
 */
export function useCreateLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateLogMutation, CreateLogMutationVariables>) {
        return Apollo.useMutation<CreateLogMutation, CreateLogMutationVariables>(CreateLogDocument, baseOptions);
      }
export type CreateLogMutationHookResult = ReturnType<typeof useCreateLogMutation>;
export type CreateLogMutationResult = Apollo.MutationResult<CreateLogMutation>;
export type CreateLogMutationOptions = Apollo.BaseMutationOptions<CreateLogMutation, CreateLogMutationVariables>;
export const GetLogListDocument = gql`
    query GetLogList($pagination: PaginationInputType, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType) {
  result: logs(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    total
    list {
      id
      user {
        id
        name
      }
      entityId
      queryEntity
      queryAction
      queryMessage
      createdAt
    }
  }
}
    `;

/**
 * __useGetLogListQuery__
 *
 * To run a query within a React component, call `useGetLogListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetLogListQuery(baseOptions: Apollo.QueryHookOptions<GetLogListQuery, GetLogListQueryVariables>) {
        return Apollo.useQuery<GetLogListQuery, GetLogListQueryVariables>(GetLogListDocument, baseOptions);
      }
export function useGetLogListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogListQuery, GetLogListQueryVariables>) {
          return Apollo.useLazyQuery<GetLogListQuery, GetLogListQueryVariables>(GetLogListDocument, baseOptions);
        }
export type GetLogListQueryHookResult = ReturnType<typeof useGetLogListQuery>;
export type GetLogListLazyQueryHookResult = ReturnType<typeof useGetLogListLazyQuery>;
export type GetLogListQueryResult = Apollo.QueryResult<GetLogListQuery, GetLogListQueryVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($order: CreateOrderDto!) {
  createOrder(order: $order) {
    id
    number
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const GetOrderDataDocument = gql`
    query getOrderData {
  products {
    id
    name
    suppliers {
      id
      name
    }
  }
}
    `;

/**
 * __useGetOrderDataQuery__
 *
 * To run a query within a React component, call `useGetOrderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderDataQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderDataQuery, GetOrderDataQueryVariables>) {
        return Apollo.useQuery<GetOrderDataQuery, GetOrderDataQueryVariables>(GetOrderDataDocument, baseOptions);
      }
export function useGetOrderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderDataQuery, GetOrderDataQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderDataQuery, GetOrderDataQueryVariables>(GetOrderDataDocument, baseOptions);
        }
export type GetOrderDataQueryHookResult = ReturnType<typeof useGetOrderDataQuery>;
export type GetOrderDataLazyQueryHookResult = ReturnType<typeof useGetOrderDataLazyQuery>;
export type GetOrderDataQueryResult = Apollo.QueryResult<GetOrderDataQuery, GetOrderDataQueryVariables>;
export const GetOrderDocument = gql`
    query getOrder($id: ID!) {
  order(id: $id) {
    id
    number
    name
    contact {
      id
      name
      email
      phone
    }
    customer {
      id
      name
      contacts {
        id
        name
      }
      type
      advertisers {
        id
        name
      }
      industryTag
      productTag
      fieldTag
    }
    files {
      id
      name
      mimetype
      createdAt
      uri
    }
    items {
      product {
        id
        name
      }
      type
      totalAmount
      serviceFee
      budget
      id
      description
      startDate
      endDate
      deposits {
        id
        amount
        status
        createdAt
        startDate
        endDate
      }
      assignee {
        id
        name
      }
      status
      productTag
      fieldTag
      belonging
      supporter {
        id
        name
      }
    }
    createdAt
    invoiceContact {
      id
      name
      email
      phone
    }
    status
    invoices {
      id
      invoiceNumber
      postDate
      createdAt
      status
      date
      items {
        id
        name
        amount
        changedAmount
        orderItem {
          id
        }
      }
      note
    }
    invoiceMethod
    currency
    locale
    paymentDays
    renewal
    changeReason
    assignedSalesUser {
      id
      name
    }
    remark
    advertiser {
      id
      name
      industryTag
      productTag
      fieldTag
    }
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($pagination: PaginationInputType!, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType!) {
  result: orders(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    total
    list {
      id
      number
      name
      contact {
        name
        id
        phone
        email
      }
      customer {
        name
        id
        type
      }
      createdAt
      updatedAt
      status
      assignedSalesUser {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, baseOptions);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, baseOptions);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const RemoveOrderDocument = gql`
    mutation removeOrder($id: ID!) {
  removeOrder(id: $id)
}
    `;
export type RemoveOrderMutationFn = Apollo.MutationFunction<RemoveOrderMutation, RemoveOrderMutationVariables>;

/**
 * __useRemoveOrderMutation__
 *
 * To run a mutation, you first call `useRemoveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderMutation, { data, loading, error }] = useRemoveOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrderMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrderMutation, RemoveOrderMutationVariables>) {
        return Apollo.useMutation<RemoveOrderMutation, RemoveOrderMutationVariables>(RemoveOrderDocument, baseOptions);
      }
export type RemoveOrderMutationHookResult = ReturnType<typeof useRemoveOrderMutation>;
export type RemoveOrderMutationResult = Apollo.MutationResult<RemoveOrderMutation>;
export type RemoveOrderMutationOptions = Apollo.BaseMutationOptions<RemoveOrderMutation, RemoveOrderMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($order: UpdateOrderDto!, $id: ID!) {
  updateOrder(order: $order, id: $id) {
    id
    number
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, baseOptions);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($status: UpdateOrderStatusDto!, $id: ID!) {
  updateOrderStatus(statusDto: $status, id: $id) {
    id
    number
    changeReason
  }
}
    `;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, baseOptions);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export const CreateOrderItemDocument = gql`
    mutation createOrderItem($orderItem: CreateOrderItemDto!) {
  createOrderItem(orderItem: $orderItem) {
    id
    description
    product {
      id
      name
    }
  }
}
    `;
export type CreateOrderItemMutationFn = Apollo.MutationFunction<CreateOrderItemMutation, CreateOrderItemMutationVariables>;

/**
 * __useCreateOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemMutation, { data, loading, error }] = useCreateOrderItemMutation({
 *   variables: {
 *      orderItem: // value for 'orderItem'
 *   },
 * });
 */
export function useCreateOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderItemMutation, CreateOrderItemMutationVariables>) {
        return Apollo.useMutation<CreateOrderItemMutation, CreateOrderItemMutationVariables>(CreateOrderItemDocument, baseOptions);
      }
export type CreateOrderItemMutationHookResult = ReturnType<typeof useCreateOrderItemMutation>;
export type CreateOrderItemMutationResult = Apollo.MutationResult<CreateOrderItemMutation>;
export type CreateOrderItemMutationOptions = Apollo.BaseMutationOptions<CreateOrderItemMutation, CreateOrderItemMutationVariables>;
export const GetOrderItemDocument = gql`
    query getOrderItem($id: ID!) {
  orderItem(id: $id) {
    product {
      id
      name
      productOrigin
    }
    type
    totalAmount
    serviceFee
    budget
    id
    description
    startDate
    endDate
    deposits {
      id
      amount
      status
      createdAt
      accountId
      startDate
      endDate
      description
      supplier {
        id
        name
      }
      accountCosts {
        id
        cost
        date
      }
      changes {
        id
        amount
        createdAt
        verifiedAt
        verified
        description
        startDate
        endDate
      }
    }
    order {
      id
      name
      status
      invoices {
        status
        items {
          amount
          name
          orderItem {
            id
          }
        }
      }
      customer {
        invoiceExpired
      }
    }
    assignee {
      id
      name
    }
    status
    purchaseOrders {
      id
      createdAt
      signedDate
      status
      paidDate
      amount
      contact {
        id
        name
        email
      }
      supplier {
        id
        name
      }
    }
    productTag
    fieldTag
    belonging
    supporter {
      id
      name
    }
  }
}
    `;

/**
 * __useGetOrderItemQuery__
 *
 * To run a query within a React component, call `useGetOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderItemQuery(baseOptions: Apollo.QueryHookOptions<GetOrderItemQuery, GetOrderItemQueryVariables>) {
        return Apollo.useQuery<GetOrderItemQuery, GetOrderItemQueryVariables>(GetOrderItemDocument, baseOptions);
      }
export function useGetOrderItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderItemQuery, GetOrderItemQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderItemQuery, GetOrderItemQueryVariables>(GetOrderItemDocument, baseOptions);
        }
export type GetOrderItemQueryHookResult = ReturnType<typeof useGetOrderItemQuery>;
export type GetOrderItemLazyQueryHookResult = ReturnType<typeof useGetOrderItemLazyQuery>;
export type GetOrderItemQueryResult = Apollo.QueryResult<GetOrderItemQuery, GetOrderItemQueryVariables>;
export const GetOrderItemListDocument = gql`
    query GetOrderItemList($pagination: PaginationInputType, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType) {
  result: orderItems(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    total
    list {
      order {
        number
        assignedSalesUser {
          name
        }
        customer {
          name
        }
        name
        invoices {
          items {
            amount
            orderItem {
              id
            }
          }
        }
      }
      id
      product {
        name
        productOrigin
      }
      type
      deposits {
        id
        amount
        supplier {
          name
        }
        accountId
        startDate
        endDate
        accountCosts {
          cost
          date
        }
      }
      status
      assignee {
        name
      }
      startDate
      endDate
      totalAmount
      serviceFee
      budget
      invoiceItems {
        amount
      }
      productTag
      fieldTag
      belonging
      supporter {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetOrderItemListQuery__
 *
 * To run a query within a React component, call `useGetOrderItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetOrderItemListQuery(baseOptions: Apollo.QueryHookOptions<GetOrderItemListQuery, GetOrderItemListQueryVariables>) {
        return Apollo.useQuery<GetOrderItemListQuery, GetOrderItemListQueryVariables>(GetOrderItemListDocument, baseOptions);
      }
export function useGetOrderItemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderItemListQuery, GetOrderItemListQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderItemListQuery, GetOrderItemListQueryVariables>(GetOrderItemListDocument, baseOptions);
        }
export type GetOrderItemListQueryHookResult = ReturnType<typeof useGetOrderItemListQuery>;
export type GetOrderItemListLazyQueryHookResult = ReturnType<typeof useGetOrderItemListLazyQuery>;
export type GetOrderItemListQueryResult = Apollo.QueryResult<GetOrderItemListQuery, GetOrderItemListQueryVariables>;
export const DeleteOrderItemDocument = gql`
    mutation deleteOrderItem($id: ID!) {
  deleteOrderItem(id: $id)
}
    `;
export type DeleteOrderItemMutationFn = Apollo.MutationFunction<DeleteOrderItemMutation, DeleteOrderItemMutationVariables>;

/**
 * __useDeleteOrderItemMutation__
 *
 * To run a mutation, you first call `useDeleteOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderItemMutation, { data, loading, error }] = useDeleteOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderItemMutation, DeleteOrderItemMutationVariables>) {
        return Apollo.useMutation<DeleteOrderItemMutation, DeleteOrderItemMutationVariables>(DeleteOrderItemDocument, baseOptions);
      }
export type DeleteOrderItemMutationHookResult = ReturnType<typeof useDeleteOrderItemMutation>;
export type DeleteOrderItemMutationResult = Apollo.MutationResult<DeleteOrderItemMutation>;
export type DeleteOrderItemMutationOptions = Apollo.BaseMutationOptions<DeleteOrderItemMutation, DeleteOrderItemMutationVariables>;
export const UpdateOrderItemDocument = gql`
    mutation updateOrderItem($orderItem: UpdateOrderItemDto!, $id: ID!) {
  updateOrderItem(orderItem: $orderItem, id: $id) {
    id
    description
    product {
      id
      name
    }
  }
}
    `;
export type UpdateOrderItemMutationFn = Apollo.MutationFunction<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;

/**
 * __useUpdateOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemMutation, { data, loading, error }] = useUpdateOrderItemMutation({
 *   variables: {
 *      orderItem: // value for 'orderItem'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>) {
        return Apollo.useMutation<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>(UpdateOrderItemDocument, baseOptions);
      }
export type UpdateOrderItemMutationHookResult = ReturnType<typeof useUpdateOrderItemMutation>;
export type UpdateOrderItemMutationResult = Apollo.MutationResult<UpdateOrderItemMutation>;
export type UpdateOrderItemMutationOptions = Apollo.BaseMutationOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($product: ProductInputType!) {
  createProduct(product: $product) {
    id
    name
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const GetProductDocument = gql`
    query getProduct($id: ID!) {
  product(id: $id) {
    id
    name
    website
    createdAt
    description
    productOrigin
    suppliers {
      id
      name
      address
      invoiceTitle
      taxId
      contacts {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductListDocument = gql`
    query getProductList {
  products {
    id
    name
    website
    createdAt
    description
    productOrigin
    suppliers {
      id
      name
    }
  }
}
    `;

/**
 * __useGetProductListQuery__
 *
 * To run a query within a React component, call `useGetProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductListQuery(baseOptions?: Apollo.QueryHookOptions<GetProductListQuery, GetProductListQueryVariables>) {
        return Apollo.useQuery<GetProductListQuery, GetProductListQueryVariables>(GetProductListDocument, baseOptions);
      }
export function useGetProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductListQuery, GetProductListQueryVariables>) {
          return Apollo.useLazyQuery<GetProductListQuery, GetProductListQueryVariables>(GetProductListDocument, baseOptions);
        }
export type GetProductListQueryHookResult = ReturnType<typeof useGetProductListQuery>;
export type GetProductListLazyQueryHookResult = ReturnType<typeof useGetProductListLazyQuery>;
export type GetProductListQueryResult = Apollo.QueryResult<GetProductListQuery, GetProductListQueryVariables>;
export const RemoveProductDocument = gql`
    mutation removeProduct($id: ID!) {
  removeProduct(id: $id)
}
    `;
export type RemoveProductMutationFn = Apollo.MutationFunction<RemoveProductMutation, RemoveProductMutationVariables>;

/**
 * __useRemoveProductMutation__
 *
 * To run a mutation, you first call `useRemoveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductMutation, { data, loading, error }] = useRemoveProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProductMutation, RemoveProductMutationVariables>) {
        return Apollo.useMutation<RemoveProductMutation, RemoveProductMutationVariables>(RemoveProductDocument, baseOptions);
      }
export type RemoveProductMutationHookResult = ReturnType<typeof useRemoveProductMutation>;
export type RemoveProductMutationResult = Apollo.MutationResult<RemoveProductMutation>;
export type RemoveProductMutationOptions = Apollo.BaseMutationOptions<RemoveProductMutation, RemoveProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($product: ProductInputType!, $id: ID!) {
  updateProduct(id: $id, product: $product) {
    id
    name
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const CreatePurchaseOrderDocument = gql`
    mutation createPurchaseOrder($purchaseOrder: CreatePurchaseOrderDto!) {
  createPurchaseOrder(purchaseOrder: $purchaseOrder) {
    id
    amount
  }
}
    `;
export type CreatePurchaseOrderMutationFn = Apollo.MutationFunction<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>;

/**
 * __useCreatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseOrderMutation, { data, loading, error }] = useCreatePurchaseOrderMutation({
 *   variables: {
 *      purchaseOrder: // value for 'purchaseOrder'
 *   },
 * });
 */
export function useCreatePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>) {
        return Apollo.useMutation<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>(CreatePurchaseOrderDocument, baseOptions);
      }
export type CreatePurchaseOrderMutationHookResult = ReturnType<typeof useCreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationResult = Apollo.MutationResult<CreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>;
export const GetPurchaseOrderDocument = gql`
    query getPurchaseOrder($id: ID!) {
  purchaseOrder(id: $id) {
    id
    status
    createdAt
    updatedAt
    signedDate
    paidDate
    amount
    description
    contact {
      id
      name
      phone
      email
    }
    orderItem {
      id
      product {
        id
      }
      order {
        assignedSalesUser {
          name
        }
      }
    }
    supplier {
      id
      name
      contacts {
        id
        name
      }
    }
    files {
      id
      name
      mimetype
      createdAt
      uri
    }
    invoiceFiles {
      id
      name
      mimetype
      createdAt
      uri
    }
    initiator {
      id
      name
    }
    initiateDate
    managerReviewer {
      id
      name
    }
    managerReviewDate
    gmReviewer {
      id
      name
    }
    gmReviewDate
    financeReviewer {
      id
      name
    }
    financeReviewDate
    note
  }
}
    `;

/**
 * __useGetPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPurchaseOrderQuery(baseOptions: Apollo.QueryHookOptions<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>) {
        return Apollo.useQuery<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>(GetPurchaseOrderDocument, baseOptions);
      }
export function useGetPurchaseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>) {
          return Apollo.useLazyQuery<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>(GetPurchaseOrderDocument, baseOptions);
        }
export type GetPurchaseOrderQueryHookResult = ReturnType<typeof useGetPurchaseOrderQuery>;
export type GetPurchaseOrderLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderLazyQuery>;
export type GetPurchaseOrderQueryResult = Apollo.QueryResult<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>;
export const GetPurchaseOrderListDocument = gql`
    query getPurchaseOrderList($pagination: PaginationInputType!, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType!) {
  result: purchaseOrders(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    list {
      id
      status
      supplier {
        id
        name
      }
      createdAt
      paidDate
      orderItem {
        order {
          assignedSalesUser {
            id
            name
          }
          number
        }
      }
      initiator {
        id
        name
      }
    }
    total
  }
}
    `;

/**
 * __useGetPurchaseOrderListQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetPurchaseOrderListQuery(baseOptions: Apollo.QueryHookOptions<GetPurchaseOrderListQuery, GetPurchaseOrderListQueryVariables>) {
        return Apollo.useQuery<GetPurchaseOrderListQuery, GetPurchaseOrderListQueryVariables>(GetPurchaseOrderListDocument, baseOptions);
      }
export function useGetPurchaseOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseOrderListQuery, GetPurchaseOrderListQueryVariables>) {
          return Apollo.useLazyQuery<GetPurchaseOrderListQuery, GetPurchaseOrderListQueryVariables>(GetPurchaseOrderListDocument, baseOptions);
        }
export type GetPurchaseOrderListQueryHookResult = ReturnType<typeof useGetPurchaseOrderListQuery>;
export type GetPurchaseOrderListLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderListLazyQuery>;
export type GetPurchaseOrderListQueryResult = Apollo.QueryResult<GetPurchaseOrderListQuery, GetPurchaseOrderListQueryVariables>;
export const RemovePurchaseOrderDocument = gql`
    mutation removePurchaseOrder($id: ID!) {
  deletePurchaseOrder(id: $id)
}
    `;
export type RemovePurchaseOrderMutationFn = Apollo.MutationFunction<RemovePurchaseOrderMutation, RemovePurchaseOrderMutationVariables>;

/**
 * __useRemovePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useRemovePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePurchaseOrderMutation, { data, loading, error }] = useRemovePurchaseOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<RemovePurchaseOrderMutation, RemovePurchaseOrderMutationVariables>) {
        return Apollo.useMutation<RemovePurchaseOrderMutation, RemovePurchaseOrderMutationVariables>(RemovePurchaseOrderDocument, baseOptions);
      }
export type RemovePurchaseOrderMutationHookResult = ReturnType<typeof useRemovePurchaseOrderMutation>;
export type RemovePurchaseOrderMutationResult = Apollo.MutationResult<RemovePurchaseOrderMutation>;
export type RemovePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<RemovePurchaseOrderMutation, RemovePurchaseOrderMutationVariables>;
export const UpdatePurchaseOrderDocument = gql`
    mutation updatePurchaseOrder($purchaseOrder: UpdatePurchaseOrderDto!, $id: ID!) {
  updatePurchaseOrder(purchaseOrder: $purchaseOrder, id: $id) {
    id
    status
  }
}
    `;
export type UpdatePurchaseOrderMutationFn = Apollo.MutationFunction<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>;

/**
 * __useUpdatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseOrderMutation, { data, loading, error }] = useUpdatePurchaseOrderMutation({
 *   variables: {
 *      purchaseOrder: // value for 'purchaseOrder'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>) {
        return Apollo.useMutation<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>(UpdatePurchaseOrderDocument, baseOptions);
      }
export type UpdatePurchaseOrderMutationHookResult = ReturnType<typeof useUpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationResult = Apollo.MutationResult<UpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>;
export const CreateRebateDocument = gql`
    mutation createRebate($rebate: RebateDto!) {
  createRebate(rebate: $rebate) {
    id
  }
}
    `;
export type CreateRebateMutationFn = Apollo.MutationFunction<CreateRebateMutation, CreateRebateMutationVariables>;

/**
 * __useCreateRebateMutation__
 *
 * To run a mutation, you first call `useCreateRebateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRebateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRebateMutation, { data, loading, error }] = useCreateRebateMutation({
 *   variables: {
 *      rebate: // value for 'rebate'
 *   },
 * });
 */
export function useCreateRebateMutation(baseOptions?: Apollo.MutationHookOptions<CreateRebateMutation, CreateRebateMutationVariables>) {
        return Apollo.useMutation<CreateRebateMutation, CreateRebateMutationVariables>(CreateRebateDocument, baseOptions);
      }
export type CreateRebateMutationHookResult = ReturnType<typeof useCreateRebateMutation>;
export type CreateRebateMutationResult = Apollo.MutationResult<CreateRebateMutation>;
export type CreateRebateMutationOptions = Apollo.BaseMutationOptions<CreateRebateMutation, CreateRebateMutationVariables>;
export const RemoveRebateDocument = gql`
    mutation removeRebate($id: ID!) {
  removeRebate(id: $id)
}
    `;
export type RemoveRebateMutationFn = Apollo.MutationFunction<RemoveRebateMutation, RemoveRebateMutationVariables>;

/**
 * __useRemoveRebateMutation__
 *
 * To run a mutation, you first call `useRemoveRebateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRebateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRebateMutation, { data, loading, error }] = useRemoveRebateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRebateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRebateMutation, RemoveRebateMutationVariables>) {
        return Apollo.useMutation<RemoveRebateMutation, RemoveRebateMutationVariables>(RemoveRebateDocument, baseOptions);
      }
export type RemoveRebateMutationHookResult = ReturnType<typeof useRemoveRebateMutation>;
export type RemoveRebateMutationResult = Apollo.MutationResult<RemoveRebateMutation>;
export type RemoveRebateMutationOptions = Apollo.BaseMutationOptions<RemoveRebateMutation, RemoveRebateMutationVariables>;
export const UpdateRebateDocument = gql`
    mutation updateRebate($id: ID!, $rebate: RebateDto!) {
  updateRebate(id: $id, rebate: $rebate) {
    id
  }
}
    `;
export type UpdateRebateMutationFn = Apollo.MutationFunction<UpdateRebateMutation, UpdateRebateMutationVariables>;

/**
 * __useUpdateRebateMutation__
 *
 * To run a mutation, you first call `useUpdateRebateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRebateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRebateMutation, { data, loading, error }] = useUpdateRebateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rebate: // value for 'rebate'
 *   },
 * });
 */
export function useUpdateRebateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRebateMutation, UpdateRebateMutationVariables>) {
        return Apollo.useMutation<UpdateRebateMutation, UpdateRebateMutationVariables>(UpdateRebateDocument, baseOptions);
      }
export type UpdateRebateMutationHookResult = ReturnType<typeof useUpdateRebateMutation>;
export type UpdateRebateMutationResult = Apollo.MutationResult<UpdateRebateMutation>;
export type UpdateRebateMutationOptions = Apollo.BaseMutationOptions<UpdateRebateMutation, UpdateRebateMutationVariables>;
export const CreateReceivedPaymentDocument = gql`
    mutation createReceivedPayment($receivedPayment: CreateReceivedPaymentDto!) {
  createReceivedPayment(receivedPayment: $receivedPayment) {
    id
    amount
  }
}
    `;
export type CreateReceivedPaymentMutationFn = Apollo.MutationFunction<CreateReceivedPaymentMutation, CreateReceivedPaymentMutationVariables>;

/**
 * __useCreateReceivedPaymentMutation__
 *
 * To run a mutation, you first call `useCreateReceivedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceivedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceivedPaymentMutation, { data, loading, error }] = useCreateReceivedPaymentMutation({
 *   variables: {
 *      receivedPayment: // value for 'receivedPayment'
 *   },
 * });
 */
export function useCreateReceivedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceivedPaymentMutation, CreateReceivedPaymentMutationVariables>) {
        return Apollo.useMutation<CreateReceivedPaymentMutation, CreateReceivedPaymentMutationVariables>(CreateReceivedPaymentDocument, baseOptions);
      }
export type CreateReceivedPaymentMutationHookResult = ReturnType<typeof useCreateReceivedPaymentMutation>;
export type CreateReceivedPaymentMutationResult = Apollo.MutationResult<CreateReceivedPaymentMutation>;
export type CreateReceivedPaymentMutationOptions = Apollo.BaseMutationOptions<CreateReceivedPaymentMutation, CreateReceivedPaymentMutationVariables>;
export const GetReceivedPaymentDocument = gql`
    query getReceivedPayment($id: ID!) {
  receivedPayment(id: $id) {
    id
    createdAt
    updatedAt
    amount
    date
    note
    remark
    status
    sourceBankCode
    sourceBankAccountNumber
    destinationBankAccountNumber
    customer {
      id
      name
      invoices {
        id
        status
        invoiceNumber
        postDate
        items {
          id
          amount
        }
      }
    }
    invoices {
      id
      status
      invoiceNumber
      postDate
      items {
        id
        amount
      }
    }
  }
}
    `;

/**
 * __useGetReceivedPaymentQuery__
 *
 * To run a query within a React component, call `useGetReceivedPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivedPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivedPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReceivedPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetReceivedPaymentQuery, GetReceivedPaymentQueryVariables>) {
        return Apollo.useQuery<GetReceivedPaymentQuery, GetReceivedPaymentQueryVariables>(GetReceivedPaymentDocument, baseOptions);
      }
export function useGetReceivedPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivedPaymentQuery, GetReceivedPaymentQueryVariables>) {
          return Apollo.useLazyQuery<GetReceivedPaymentQuery, GetReceivedPaymentQueryVariables>(GetReceivedPaymentDocument, baseOptions);
        }
export type GetReceivedPaymentQueryHookResult = ReturnType<typeof useGetReceivedPaymentQuery>;
export type GetReceivedPaymentLazyQueryHookResult = ReturnType<typeof useGetReceivedPaymentLazyQuery>;
export type GetReceivedPaymentQueryResult = Apollo.QueryResult<GetReceivedPaymentQuery, GetReceivedPaymentQueryVariables>;
export const GetReceivedPaymentListDocument = gql`
    query getReceivedPaymentList($pagination: PaginationInputType!, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType!) {
  result: receivedPayments(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    list {
      id
      createdAt
      updatedAt
      amount
      date
      note
      remark
      status
      sourceBankAccountNumber
      destinationBankAccountNumber
      customer {
        id
        name
      }
      invoices {
        id
      }
    }
    total
  }
}
    `;

/**
 * __useGetReceivedPaymentListQuery__
 *
 * To run a query within a React component, call `useGetReceivedPaymentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivedPaymentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivedPaymentListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetReceivedPaymentListQuery(baseOptions: Apollo.QueryHookOptions<GetReceivedPaymentListQuery, GetReceivedPaymentListQueryVariables>) {
        return Apollo.useQuery<GetReceivedPaymentListQuery, GetReceivedPaymentListQueryVariables>(GetReceivedPaymentListDocument, baseOptions);
      }
export function useGetReceivedPaymentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivedPaymentListQuery, GetReceivedPaymentListQueryVariables>) {
          return Apollo.useLazyQuery<GetReceivedPaymentListQuery, GetReceivedPaymentListQueryVariables>(GetReceivedPaymentListDocument, baseOptions);
        }
export type GetReceivedPaymentListQueryHookResult = ReturnType<typeof useGetReceivedPaymentListQuery>;
export type GetReceivedPaymentListLazyQueryHookResult = ReturnType<typeof useGetReceivedPaymentListLazyQuery>;
export type GetReceivedPaymentListQueryResult = Apollo.QueryResult<GetReceivedPaymentListQuery, GetReceivedPaymentListQueryVariables>;
export const LinkReceivedPaymentDocument = gql`
    mutation linkReceivedPayment($links: [ID!]!, $id: ID!) {
  linkReceivedPayment(links: $links, id: $id) {
    id
    status
    invoices {
      id
      status
    }
  }
}
    `;
export type LinkReceivedPaymentMutationFn = Apollo.MutationFunction<LinkReceivedPaymentMutation, LinkReceivedPaymentMutationVariables>;

/**
 * __useLinkReceivedPaymentMutation__
 *
 * To run a mutation, you first call `useLinkReceivedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkReceivedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkReceivedPaymentMutation, { data, loading, error }] = useLinkReceivedPaymentMutation({
 *   variables: {
 *      links: // value for 'links'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkReceivedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<LinkReceivedPaymentMutation, LinkReceivedPaymentMutationVariables>) {
        return Apollo.useMutation<LinkReceivedPaymentMutation, LinkReceivedPaymentMutationVariables>(LinkReceivedPaymentDocument, baseOptions);
      }
export type LinkReceivedPaymentMutationHookResult = ReturnType<typeof useLinkReceivedPaymentMutation>;
export type LinkReceivedPaymentMutationResult = Apollo.MutationResult<LinkReceivedPaymentMutation>;
export type LinkReceivedPaymentMutationOptions = Apollo.BaseMutationOptions<LinkReceivedPaymentMutation, LinkReceivedPaymentMutationVariables>;
export const RemoveReceivedPaymentDocument = gql`
    mutation removeReceivedPayment($id: ID!) {
  removeReceivedPayment(id: $id)
}
    `;
export type RemoveReceivedPaymentMutationFn = Apollo.MutationFunction<RemoveReceivedPaymentMutation, RemoveReceivedPaymentMutationVariables>;

/**
 * __useRemoveReceivedPaymentMutation__
 *
 * To run a mutation, you first call `useRemoveReceivedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReceivedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReceivedPaymentMutation, { data, loading, error }] = useRemoveReceivedPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveReceivedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReceivedPaymentMutation, RemoveReceivedPaymentMutationVariables>) {
        return Apollo.useMutation<RemoveReceivedPaymentMutation, RemoveReceivedPaymentMutationVariables>(RemoveReceivedPaymentDocument, baseOptions);
      }
export type RemoveReceivedPaymentMutationHookResult = ReturnType<typeof useRemoveReceivedPaymentMutation>;
export type RemoveReceivedPaymentMutationResult = Apollo.MutationResult<RemoveReceivedPaymentMutation>;
export type RemoveReceivedPaymentMutationOptions = Apollo.BaseMutationOptions<RemoveReceivedPaymentMutation, RemoveReceivedPaymentMutationVariables>;
export const UpdateReceivedPaymentDocument = gql`
    mutation updateReceivedPayment($receivedPayment: UpdateReceivedPaymentDto!, $id: ID!) {
  updateReceivedPayment(receivedPayment: $receivedPayment, id: $id) {
    id
    status
  }
}
    `;
export type UpdateReceivedPaymentMutationFn = Apollo.MutationFunction<UpdateReceivedPaymentMutation, UpdateReceivedPaymentMutationVariables>;

/**
 * __useUpdateReceivedPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateReceivedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceivedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceivedPaymentMutation, { data, loading, error }] = useUpdateReceivedPaymentMutation({
 *   variables: {
 *      receivedPayment: // value for 'receivedPayment'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateReceivedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceivedPaymentMutation, UpdateReceivedPaymentMutationVariables>) {
        return Apollo.useMutation<UpdateReceivedPaymentMutation, UpdateReceivedPaymentMutationVariables>(UpdateReceivedPaymentDocument, baseOptions);
      }
export type UpdateReceivedPaymentMutationHookResult = ReturnType<typeof useUpdateReceivedPaymentMutation>;
export type UpdateReceivedPaymentMutationResult = Apollo.MutationResult<UpdateReceivedPaymentMutation>;
export type UpdateReceivedPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateReceivedPaymentMutation, UpdateReceivedPaymentMutationVariables>;
export const GetOrderItemReportDocument = gql`
    query getOrderItemReport($year: Int!, $month: Int!) {
  orderItemReport(year: $year, month: $month) {
    id
    budget
    accumulatedCosts
    accumulatedOutstandingAmount
    currentMonthCosts
    currentMonthOutstandingAmount
    overSpendAmount
    overSpendCostRatio
    overSpendRatio
    accountIds
    type
    endDate
    estimatedCostPercentage
    estimatedProfit
    estimatedProfitPercentage
    markup
    markupPercentage
    name
    orderNumber
    previousCosts
    previouslyOutstandingAmount
    previousIncome
    currentMonthIncome
    accumulatedIncome
    serviceFee
    serviceFeePercentage
    startDate
    summarizedTotal
    totalAmount
  }
}
    `;

/**
 * __useGetOrderItemReportQuery__
 *
 * To run a query within a React component, call `useGetOrderItemReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemReportQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetOrderItemReportQuery(baseOptions: Apollo.QueryHookOptions<GetOrderItemReportQuery, GetOrderItemReportQueryVariables>) {
        return Apollo.useQuery<GetOrderItemReportQuery, GetOrderItemReportQueryVariables>(GetOrderItemReportDocument, baseOptions);
      }
export function useGetOrderItemReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderItemReportQuery, GetOrderItemReportQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderItemReportQuery, GetOrderItemReportQueryVariables>(GetOrderItemReportDocument, baseOptions);
        }
export type GetOrderItemReportQueryHookResult = ReturnType<typeof useGetOrderItemReportQuery>;
export type GetOrderItemReportLazyQueryHookResult = ReturnType<typeof useGetOrderItemReportLazyQuery>;
export type GetOrderItemReportQueryResult = Apollo.QueryResult<GetOrderItemReportQuery, GetOrderItemReportQueryVariables>;
export const RealAccountCostsDocument = gql`
    query realAccountCosts($month: Int!, $year: Int!, $productOrigin: ProductOrigin!) {
  realAccountCosts(month: $month, year: $year, productOrigin: $productOrigin) {
    id
    cost
    month
    year
    accountId
    productOrigin
  }
}
    `;

/**
 * __useRealAccountCostsQuery__
 *
 * To run a query within a React component, call `useRealAccountCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealAccountCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealAccountCostsQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      productOrigin: // value for 'productOrigin'
 *   },
 * });
 */
export function useRealAccountCostsQuery(baseOptions: Apollo.QueryHookOptions<RealAccountCostsQuery, RealAccountCostsQueryVariables>) {
        return Apollo.useQuery<RealAccountCostsQuery, RealAccountCostsQueryVariables>(RealAccountCostsDocument, baseOptions);
      }
export function useRealAccountCostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RealAccountCostsQuery, RealAccountCostsQueryVariables>) {
          return Apollo.useLazyQuery<RealAccountCostsQuery, RealAccountCostsQueryVariables>(RealAccountCostsDocument, baseOptions);
        }
export type RealAccountCostsQueryHookResult = ReturnType<typeof useRealAccountCostsQuery>;
export type RealAccountCostsLazyQueryHookResult = ReturnType<typeof useRealAccountCostsLazyQuery>;
export type RealAccountCostsQueryResult = Apollo.QueryResult<RealAccountCostsQuery, RealAccountCostsQueryVariables>;
export const RemoveRealAccountCostsDocument = gql`
    mutation RemoveRealAccountCosts($year: Int!, $month: Int!, $productOrigin: ProductOrigin!) {
  removeRealAccountCosts(
    year: $year
    month: $month
    productOrigin: $productOrigin
  )
}
    `;
export type RemoveRealAccountCostsMutationFn = Apollo.MutationFunction<RemoveRealAccountCostsMutation, RemoveRealAccountCostsMutationVariables>;

/**
 * __useRemoveRealAccountCostsMutation__
 *
 * To run a mutation, you first call `useRemoveRealAccountCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRealAccountCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRealAccountCostsMutation, { data, loading, error }] = useRemoveRealAccountCostsMutation({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      productOrigin: // value for 'productOrigin'
 *   },
 * });
 */
export function useRemoveRealAccountCostsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRealAccountCostsMutation, RemoveRealAccountCostsMutationVariables>) {
        return Apollo.useMutation<RemoveRealAccountCostsMutation, RemoveRealAccountCostsMutationVariables>(RemoveRealAccountCostsDocument, baseOptions);
      }
export type RemoveRealAccountCostsMutationHookResult = ReturnType<typeof useRemoveRealAccountCostsMutation>;
export type RemoveRealAccountCostsMutationResult = Apollo.MutationResult<RemoveRealAccountCostsMutation>;
export type RemoveRealAccountCostsMutationOptions = Apollo.BaseMutationOptions<RemoveRealAccountCostsMutation, RemoveRealAccountCostsMutationVariables>;
export const CreateSupplierDocument = gql`
    mutation createSupplier($supplier: SupplierInputType!) {
  createSupplier(supplier: $supplier) {
    id
    name
    address
    invoiceTitle
    taxId
  }
}
    `;
export type CreateSupplierMutationFn = Apollo.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      supplier: // value for 'supplier'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, baseOptions);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const GetSupplierDocument = gql`
    query getSupplier($id: ID!) {
  supplier(id: $id) {
    id
    name
    phone
    address
    invoiceTitle
    taxId
    products {
      id
      name
      website
      createdAt
      description
      productOrigin
    }
    bankCode
    bankAccountNumber
    contacts {
      id
      name
      email
      phone
    }
  }
}
    `;

/**
 * __useGetSupplierQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupplierQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
        return Apollo.useQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
      }
export function useGetSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
          return Apollo.useLazyQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
        }
export type GetSupplierQueryHookResult = ReturnType<typeof useGetSupplierQuery>;
export type GetSupplierLazyQueryHookResult = ReturnType<typeof useGetSupplierLazyQuery>;
export type GetSupplierQueryResult = Apollo.QueryResult<GetSupplierQuery, GetSupplierQueryVariables>;
export const GetSupplierListDocument = gql`
    query getSupplierList {
  suppliers {
    id
    name
    phone
    address
    invoiceTitle
    taxId
    bankCode
    bankAccountNumber
  }
}
    `;

/**
 * __useGetSupplierListQuery__
 *
 * To run a query within a React component, call `useGetSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupplierListQuery(baseOptions?: Apollo.QueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
        return Apollo.useQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
      }
export function useGetSupplierListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
          return Apollo.useLazyQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
        }
export type GetSupplierListQueryHookResult = ReturnType<typeof useGetSupplierListQuery>;
export type GetSupplierListLazyQueryHookResult = ReturnType<typeof useGetSupplierListLazyQuery>;
export type GetSupplierListQueryResult = Apollo.QueryResult<GetSupplierListQuery, GetSupplierListQueryVariables>;
export const RemoveSupplierDocument = gql`
    mutation removeSupplier($id: ID!) {
  removeSupplier(id: $id)
}
    `;
export type RemoveSupplierMutationFn = Apollo.MutationFunction<RemoveSupplierMutation, RemoveSupplierMutationVariables>;

/**
 * __useRemoveSupplierMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierMutation, { data, loading, error }] = useRemoveSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSupplierMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplierMutation, RemoveSupplierMutationVariables>) {
        return Apollo.useMutation<RemoveSupplierMutation, RemoveSupplierMutationVariables>(RemoveSupplierDocument, baseOptions);
      }
export type RemoveSupplierMutationHookResult = ReturnType<typeof useRemoveSupplierMutation>;
export type RemoveSupplierMutationResult = Apollo.MutationResult<RemoveSupplierMutation>;
export type RemoveSupplierMutationOptions = Apollo.BaseMutationOptions<RemoveSupplierMutation, RemoveSupplierMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation updateSupplier($supplier: SupplierInputType!, $id: ID!) {
  updateSupplier(id: $id, supplier: $supplier) {
    id
    name
  }
}
    `;
export type UpdateSupplierMutationFn = Apollo.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      supplier: // value for 'supplier'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        return Apollo.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, baseOptions);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const CreateUpgradeDocument = gql`
    mutation createUpgrade($upgrade: UpgradeInputType!) {
  createUpgrade(upgrade: $upgrade) {
    id
  }
}
    `;
export type CreateUpgradeMutationFn = Apollo.MutationFunction<CreateUpgradeMutation, CreateUpgradeMutationVariables>;

/**
 * __useCreateUpgradeMutation__
 *
 * To run a mutation, you first call `useCreateUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpgradeMutation, { data, loading, error }] = useCreateUpgradeMutation({
 *   variables: {
 *      upgrade: // value for 'upgrade'
 *   },
 * });
 */
export function useCreateUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpgradeMutation, CreateUpgradeMutationVariables>) {
        return Apollo.useMutation<CreateUpgradeMutation, CreateUpgradeMutationVariables>(CreateUpgradeDocument, baseOptions);
      }
export type CreateUpgradeMutationHookResult = ReturnType<typeof useCreateUpgradeMutation>;
export type CreateUpgradeMutationResult = Apollo.MutationResult<CreateUpgradeMutation>;
export type CreateUpgradeMutationOptions = Apollo.BaseMutationOptions<CreateUpgradeMutation, CreateUpgradeMutationVariables>;
export const GetUpgradeDocument = gql`
    query getUpgrade($id: ID!) {
  upgrade(id: $id) {
    id
    createdAt
    updatedAt
    status
    customer {
      id
      name
      creditClass
    }
    ae {
      id
      name
    }
    description
    lastThreeMonthAmount
    targetCreditClass
    finance {
      id
      name
    }
    receivableAmount
    unrecordedAmount
    overdue
    financeComment
    manager {
      id
      name
    }
    managerComment
    gm {
      id
      name
    }
    gmComment
  }
}
    `;

/**
 * __useGetUpgradeQuery__
 *
 * To run a query within a React component, call `useGetUpgradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpgradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpgradeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUpgradeQuery(baseOptions: Apollo.QueryHookOptions<GetUpgradeQuery, GetUpgradeQueryVariables>) {
        return Apollo.useQuery<GetUpgradeQuery, GetUpgradeQueryVariables>(GetUpgradeDocument, baseOptions);
      }
export function useGetUpgradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpgradeQuery, GetUpgradeQueryVariables>) {
          return Apollo.useLazyQuery<GetUpgradeQuery, GetUpgradeQueryVariables>(GetUpgradeDocument, baseOptions);
        }
export type GetUpgradeQueryHookResult = ReturnType<typeof useGetUpgradeQuery>;
export type GetUpgradeLazyQueryHookResult = ReturnType<typeof useGetUpgradeLazyQuery>;
export type GetUpgradeQueryResult = Apollo.QueryResult<GetUpgradeQuery, GetUpgradeQueryVariables>;
export const GetUpgradesDocument = gql`
    query getUpgrades($pagination: PaginationInputType!, $filtering: [FilteringInputType!]!, $ordering: OrderingInputType!) {
  result: upgrades(
    pagination: $pagination
    filtering: $filtering
    ordering: $ordering
  ) {
    list {
      id
      createdAt
      updatedAt
      status
      description
      targetCreditClass
      customer {
        id
        name
      }
      ae {
        id
        name
      }
    }
    total
  }
}
    `;

/**
 * __useGetUpgradesQuery__
 *
 * To run a query within a React component, call `useGetUpgradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpgradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpgradesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filtering: // value for 'filtering'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetUpgradesQuery(baseOptions: Apollo.QueryHookOptions<GetUpgradesQuery, GetUpgradesQueryVariables>) {
        return Apollo.useQuery<GetUpgradesQuery, GetUpgradesQueryVariables>(GetUpgradesDocument, baseOptions);
      }
export function useGetUpgradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpgradesQuery, GetUpgradesQueryVariables>) {
          return Apollo.useLazyQuery<GetUpgradesQuery, GetUpgradesQueryVariables>(GetUpgradesDocument, baseOptions);
        }
export type GetUpgradesQueryHookResult = ReturnType<typeof useGetUpgradesQuery>;
export type GetUpgradesLazyQueryHookResult = ReturnType<typeof useGetUpgradesLazyQuery>;
export type GetUpgradesQueryResult = Apollo.QueryResult<GetUpgradesQuery, GetUpgradesQueryVariables>;
export const GetUpgradesBriefDocument = gql`
    query getUpgradesBrief {
  upgradeList {
    id
    status
  }
}
    `;

/**
 * __useGetUpgradesBriefQuery__
 *
 * To run a query within a React component, call `useGetUpgradesBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpgradesBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpgradesBriefQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpgradesBriefQuery(baseOptions?: Apollo.QueryHookOptions<GetUpgradesBriefQuery, GetUpgradesBriefQueryVariables>) {
        return Apollo.useQuery<GetUpgradesBriefQuery, GetUpgradesBriefQueryVariables>(GetUpgradesBriefDocument, baseOptions);
      }
export function useGetUpgradesBriefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpgradesBriefQuery, GetUpgradesBriefQueryVariables>) {
          return Apollo.useLazyQuery<GetUpgradesBriefQuery, GetUpgradesBriefQueryVariables>(GetUpgradesBriefDocument, baseOptions);
        }
export type GetUpgradesBriefQueryHookResult = ReturnType<typeof useGetUpgradesBriefQuery>;
export type GetUpgradesBriefLazyQueryHookResult = ReturnType<typeof useGetUpgradesBriefLazyQuery>;
export type GetUpgradesBriefQueryResult = Apollo.QueryResult<GetUpgradesBriefQuery, GetUpgradesBriefQueryVariables>;
export const RemoveUpgradeDocument = gql`
    mutation removeUpgrade($id: ID!) {
  removeUpgrade(id: $id)
}
    `;
export type RemoveUpgradeMutationFn = Apollo.MutationFunction<RemoveUpgradeMutation, RemoveUpgradeMutationVariables>;

/**
 * __useRemoveUpgradeMutation__
 *
 * To run a mutation, you first call `useRemoveUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUpgradeMutation, { data, loading, error }] = useRemoveUpgradeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUpgradeMutation, RemoveUpgradeMutationVariables>) {
        return Apollo.useMutation<RemoveUpgradeMutation, RemoveUpgradeMutationVariables>(RemoveUpgradeDocument, baseOptions);
      }
export type RemoveUpgradeMutationHookResult = ReturnType<typeof useRemoveUpgradeMutation>;
export type RemoveUpgradeMutationResult = Apollo.MutationResult<RemoveUpgradeMutation>;
export type RemoveUpgradeMutationOptions = Apollo.BaseMutationOptions<RemoveUpgradeMutation, RemoveUpgradeMutationVariables>;
export const UpdateUpgradeDocument = gql`
    mutation updateUpgrade($upgrade: UpgradeInputType!, $id: ID!) {
  updateUpgrade(upgrade: $upgrade, id: $id) {
    id
  }
}
    `;
export type UpdateUpgradeMutationFn = Apollo.MutationFunction<UpdateUpgradeMutation, UpdateUpgradeMutationVariables>;

/**
 * __useUpdateUpgradeMutation__
 *
 * To run a mutation, you first call `useUpdateUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUpgradeMutation, { data, loading, error }] = useUpdateUpgradeMutation({
 *   variables: {
 *      upgrade: // value for 'upgrade'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUpgradeMutation, UpdateUpgradeMutationVariables>) {
        return Apollo.useMutation<UpdateUpgradeMutation, UpdateUpgradeMutationVariables>(UpdateUpgradeDocument, baseOptions);
      }
export type UpdateUpgradeMutationHookResult = ReturnType<typeof useUpdateUpgradeMutation>;
export type UpdateUpgradeMutationResult = Apollo.MutationResult<UpdateUpgradeMutation>;
export type UpdateUpgradeMutationOptions = Apollo.BaseMutationOptions<UpdateUpgradeMutation, UpdateUpgradeMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($user: CreateUserDTO!) {
  createUser(user: $user) {
    id
    name
    email
    googleId
    roles {
      id
      name
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const GetRolesDocument = gql`
    query getRoles {
  roles {
    id
    name
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, baseOptions);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, baseOptions);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetUserListDocument = gql`
    query getUserList {
  users {
    id
    name
    email
    disabled
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserListQuery__
 *
 * To run a query within a React component, call `useGetUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
        return Apollo.useQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, baseOptions);
      }
export function useGetUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
          return Apollo.useLazyQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, baseOptions);
        }
export type GetUserListQueryHookResult = ReturnType<typeof useGetUserListQuery>;
export type GetUserListLazyQueryHookResult = ReturnType<typeof useGetUserListLazyQuery>;
export type GetUserListQueryResult = Apollo.QueryResult<GetUserListQuery, GetUserListQueryVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($id: ID!) {
  removeUser(id: $id)
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $user: UpdateUserDto!) {
  updateUser(id: $id, user: $user) {
    id
    email
    name
    roles {
      id
      name
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;